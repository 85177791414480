import React, { createContext, useEffect, useState  } from 'react';

export const GlobalContext = React.createContext(null);
export const GlobalProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user){
      fetch('/api/initiate-advisor')
        .then(response => {
          if (response.status === 401) { // Check if the status is 401
            return response.json();
          }
          return response.json();
        })
        .then(data => {
          if (data && data.error && data.error === "not_authenticated") {
            window.location.href = data.redirect_url; // Redirect to the login page
          } else {
            setUser(data); // Assuming 'data' is the user object
            setLoading(false);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setError(error);
          setLoading(false);
        });
    }
  }, [user]);
  

  return (
    <GlobalContext.Provider value={{ user, loading, error }}>
      {children}
    </GlobalContext.Provider>
  );
};

