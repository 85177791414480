import React, { useEffect, useState, useMemo } from 'react';
import Explore from '../components/explore'
import Image from '../components/image'
import Helmet from 'react-helmet'
import { GetStarted, useAdVariation} from '../components/CTA'
import { Logos, Numbers } from '../components/SocialProof'
import { Switch } from "../components/ui/switch"
import { MiniExplore, ExplorePolicies } from '../components/explore';
import posthog from 'posthog-js'



const Main = () => {
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const adVariation = useAdVariation();

  const toggleSection = () => {
    if (isSwitchChecked) {
      document.getElementById('forAdvisors').classList.remove('hidden');
      document.getElementById('forMembers').classList.add('hidden');
      document.getElementById('advisorsChecked').classList.remove('text-white-1');
      document.getElementById('advisorsChecked').classList.add('font-semibold', 'text-green-2');
      document.getElementById('membersChecked').classList.remove('font-semibold', 'text-green-2');
      document.getElementById('membersChecked').classList.add('text-white-1');
    } else {
      document.getElementById('forAdvisors').classList.add('hidden');
      document.getElementById('forMembers').classList.remove('hidden');
      document.getElementById('membersChecked').classList.remove('text-white-1');
      document.getElementById('membersChecked').classList.add('font-semibold', 'text-green-2');
      document.getElementById('advisorsChecked').classList.remove('font-semibold', 'text-green-2');
      document.getElementById('advisorsChecked').classList.add('text-white-1');
    }
  };
  
  useEffect(() => {
    toggleSection();
  }, [isSwitchChecked]);

  posthog.capture(
    'initial',
    {
      $set_once: { initial_url: `/` },
      ad_variation: adVariation
    }
  )



  return (
    <section>
      <Helmet>
        <title>Whatins</title>
        <meta name="description" content="Discover what people like you are buying and their insights, while exploring your personalised insurance options without sharing your phone number. Talk only to the experts you choose."/>
        <meta property="og:title" content="Whatins"/>
        <meta property="og:description" content="Discover what people like you are buying and their insights, while exploring your personalised insurance options without sharing your phone number. Talk only to the experts you choose."/>
        <meta property="og:image" content="/images/whatins.png"/>
        <meta property="og:url" content="https://www.whatins.sg"/>
      </Helmet>
      <section className="bg-white-1">
    <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg:gap-12 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="place-self-center mr-auto mb-10 lg:col-span-7 xl:col-span-7 xl:mb-0">
            <h1 className="primary-h1 mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Finally, insurance made transparent</h1>
            <p className="mb-6 max-w-2xl font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Discover what people like you are buying and their insights, while exploring your personalised insurance options without sharing your phone number. Talk only to the experts you choose.</p>
            <a href="/lp/get-proposals?source=home" className="primary-button1 py-2">
            Get your free personalised plans →</a>
            <div className="justify-between pt-12 mx-auto mt-14 border-t border-gray-300 xl:flex dark:border-gray-700 dark:text-white">
            <Numbers/>
            </div>
        </div>
        <div className="justify-center p-4 max-w-screen-s lg:mt-0 lg:col-span-5 xl:col-span-5 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700">
        <Image src="whatins-home-1.svg" alt="what insurance is for you?" imgClass='w-full'/>
        </div>                
    </div>
</section>
      <div id='why-whatins' className='bg-green-1'>
        <div className='flex flex-col px-4 py-8 md:py-16 max-w-screen-2xl mx-auto'>
          <div className='flex flex-col max-w-screen-md mx-auto'>
            <Image src='favicon.svg' className='mx-auto'/>
          <h2 className='primary-h3 text-center'>Why choose Whatins for insurance needs?</h2>
          <p className='text-center py-1 text-white-1'>
          By working through Whatins, you are getting a diverse set of proposals, allowing you to explore all your options at once. Plus, we don't ask for your number so you can't ever get spammed.
          </p>
          </div>
          <div className='flex items-center space-x-2 mx-auto py-4'>
          <div id='membersChecked' className='font-semibold text-green-2'>For Members</div>
          <Switch
            checked={isSwitchChecked} 
            onCheckedChange={setIsSwitchChecked}
          />
          <div id='advisorsChecked' >For Advisors</div>
          </div>

          <div id='forMembers' className='flex flex-col max-w-screen-xl mx-auto py-3 gap-12'>
            <div className='flex flex-col md:flex-row gap-16'>
              <div className='flex flex-col md:basis-1/3 items-start'>
                <Image src="whatins-member-1.svg" alt="5" imgClass='aspect-square' className='aspect-square'/>
                <p className='primary-h3 text-h4'>No fuss, no hassle</p>
                <p className='text-white-1'>Advisors can not spam you as we do not ask for your phone number and do not give your email until after they submit their suggestions. This allows you to take your time deciding on the right policies for you.</p>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
                <Image src="whatins-member-2.svg" alt="5" imgClass='aspect-square' className='aspect-square'/>
                <p className='primary-h3 text-h4'>Premiums On Us</p>
                <p className='text-white-1'>Sign up to get matched with advisors and you'll be entered into our recurring giveaway. You could win premium-free coverage month after month! <a className='primary-link text-white-1 hover:text-green-2' href='/lp/get-proposals?source=premium#premium-on-us'>Learn more</a></p>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
                <Image src="whatins-member-3.svg" alt="5" imgClass='items-center aspect-square' className='aspect-square'/>
                <p className='primary-h3 text-h4'>Don't overpay</p>
                <p className='text-white-1'>By getting 5 different advisors to share their thoughts, you can properly compare your options instead and instantly identify the advisors who are trying to oversell you.</p>
              </div>
            </div>
            <a href="/lp/get-proposals?source=home" className="primary-button1 text-center mx-auto">Get started →</a>
          </div>
          <div id='forAdvisors' className=' hiddens flex flex-col max-w-screen-xl mx-auto py-3 gap-12'>
            <div className='flex flex-col md:flex-row gap-16'>
              <div className='flex flex-col md:basis-1/3 items-start'>
              <Image src="whatins-advisor-1.svg" alt="5" imgClass='aspect-square' className='aspect-square'/>
              <p className='primary-h3 text-h4'>Quality Prospects</p>
              <p className='text-white-1'>The prospects are triple vetted before they are accepted to ensure that they are serious about exploring their insurance options.</p>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
              <Image src="whatins-advisor-2.svg" alt="5" imgClass='items-center aspect-square' className='aspect-square'/>
              <p className='primary-h3 text-h4'>Easy communication channels</p>
              <p className='text-white-1'>Although you will not be given their contact until after submitting your suggestions, we have a telegram bot that will allow you to continue to engage with your prospect.</p>
              </div>
              <div className='flex flex-col md:basis-1/3 items-start'>
              <Image src="whatins-advisor-3.svg" alt="5" imgClass='items-center aspect-square' className='aspect-square'/>
              <p className='primary-h3 text-h4'>Easy to start</p>
              <p className='text-white-1'>Test out this platform at your own pace. Costs less than $50 to start.</p>
              </div>
            </div>
          <a href="/advisor-register" className="primary-button1 text-white text-center mx-auto">Help members with their insurance needs →</a>

          </div>
        </div>
      </div>
      <div id='Explore' className='bg-white-1 py-1.5'>
      <MiniExplore criteria='' header="Explore insurance stories" paragraph='Find out what insurance products people like you purchased and get the inside scoop about their concerns, thought process and insurance tips.' paragraph_class='text-center'/>
        
      </div>
      <div className='bg-green-2'>
        <div className='max-w-screen-xl mx-auto'>
          <ExplorePolicies/>
        </div>
      </div>
      <div id='get-started' className='bg-white-1'>
        <div className='flex flex-col max-w-screen-2xl mx-auto'>
          <GetStarted source='home' />
        </div>
      </div>
    </section>
  );
};


export default Main;
