import React, { useState, useEffect } from 'react';
import { useToast } from "../components/ui/use-toast"
import { useNavigate } from 'react-router-dom';

const DeleteComponent = () => {
  const [id, setId] = useState('');
  const { toast } = useToast()

  const handleDelete = async () => {
    try {
      const response = await fetch(`/api/delete-fake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });
      if (response.status === 200) {
        toast({
          title: `${id} was successfully deleted`,
        });
      } else if (response.status === 400) {
        const error = await response.json(); // Assuming the error message is in the response body
        toast({
          title: `Error deleting`,
          description: error.message, // Assuming error message is provided by your API
        });
      }
    } catch (error) {
      toast({
        title: `Error deleting`,
        description: error.message,
      });
    }
  };
  
  // Modif

  return (
    <div className='flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2'>
      <h1 className='text-xl font-bold'>Manually delete prospect</h1>
      <p>This is for all the fake prospects we create. It will delete the prospect from airtable, firebase and convertkit</p>
      <input
        type="text"
          className='px-2'
          value={id}
        onChange={(e) => setId(e.target.value)}
        placeholder="Enter ID to delete"
      />
      <button className='bg-purple-500 p-2 rounded-2xl text-white' onClick={handleDelete}>Delete</button>
    </div>
  );
};

const AddComponent = () => {
  const [prospectID, setProspectID] = useState('');
  const [advisorIDsInput, setAdvisorIDsInput] = useState('');
  const [isFree, setIsFree] = useState(false);
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const advisorIDs = advisorIDsInput.split(/[,\s]+/).filter(id => id !== '');    try {
      const response = await fetch('/api/manual-add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prospectID,
          advisorIDs,
          isFree,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        toast({
          title: `Successfully added advisor ${advisorIDs.join(', ')} to prospect ${prospectID}`,
        })
      } else if (response.status === 400) {
        const error = await response.json(); // Assuming the error message is in the response body
        toast({
          title: `Error adding advisor`,
          description: error.message, // Corrected
        })
      }
    } catch (error) {
      toast({
        title: `Error deleting`,
        description: error.message,
      });
    }
    setIsLoading(false)
  };

  return (
    isLoading ? (
      <div>Loading...</div>
    ) : (
      <div className='flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2'>
        <h1 className='text-xl font-bold'>Manually add advisor to prospect</h1>
        <p>This will give an advisor the right to </p>
        <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
      <input
        type="text"
        value={prospectID}
        onChange={(e) => setProspectID(e.target.value)}
        placeholder="Prospect ID"
      />
      <input
        type="text"
        value={advisorIDsInput}
        onChange={(e) => setAdvisorIDsInput(e.target.value)}
        placeholder="Advisor IDs (comma-separated)"
      />
      <label>
        <input
          type="checkbox"
          checked={isFree}
          onChange={(e) => setIsFree(e.target.checked)}
          className='mr-2'
        />
        Free?
      </label>
      <button type="submit" className='primary-button2 w-full'>Add</button>
    </form>
      </div>
    )
  );

};

const AssignAdvisorsComponent = () => {
  const [prospectID, setProspectID] = useState('');
  const [slots, setSlots] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleAssign = async () => {
    setIsLoading(true);
    try {
      const formattedReleaseDate = formatDate(releaseDate);
      const response = await fetch(`../api/assign-advisors`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          prospectID, 
          member_slots: slots ? parseInt(slots) : undefined,
          releaseDate: formattedReleaseDate 
        }),
      });
      
      if (response.ok) {
        const data = await response.json();
        toast({
          title: `Successfully assigned advisors to prospect ${prospectID}`,
          description: `${data.exclusiveWinners.length} advisors assigned. Release date: ${formattedReleaseDate}`,
        });
      } else {
        const error = await response.json();
        toast({
          title: `Error assigning advisors`,
          description: error.message,
          variant: "destructive",
        });
      }
    } catch (error) {
      toast({
        title: `Error assigning advisors`,
        description: error.message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  return (
    isLoading ? (
      <div>Loading...</div>
    ) : (
      <div className='flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2'>
        <h1 className='text-xl font-bold'>Assign Advisors to Prospect</h1>
        <p>This will assign advisors to the specified prospect</p>
        <input
          type="text"
          className='px-2 py-1 rounded'
          value={prospectID}
          onChange={(e) => setProspectID(e.target.value)}
          placeholder="Prospect ID"
        />
        <input
          type="number"
          className='px-2 py-1 rounded'
          value={slots}
          onChange={(e) => setSlots(e.target.value)}
          placeholder="Number of slots (optional)"
        />
        <input
          type="date"
          className='px-2 py-1 rounded'
          value={releaseDate}
          onChange={(e) => setReleaseDate(e.target.value)}
          placeholder="Release Date"
        />
        <button 
          className='bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors'
          onClick={handleAssign}
        >
          Assign Advisors
        </button>
      </div>
    )
  );
};

const ViewAsAdvisorComponent = () => {
  const [advisorID, setAdvisorID] = useState('');
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false);

  const handleViewAsAdvisor = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/view-as-advisor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ advisorID }),
      });
      
      if (response.ok) {
        toast({
          title: `Successfully changed to view as advisor ${advisorID}`,
        });
        setAdvisorID('');
      } else {
        const error = await response.json();
        toast({
          title: `Error changing advisor view`,
          description: error.message,
          variant: "destructive",
        });
      }
    } catch (error) {
      toast({
        title: `Error changing advisor view`,
        description: error.message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  return (
    isLoading ? (
      <div>Loading...</div>
    ) : (
      <div className='flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2'>
        <h1 className='text-xl font-bold'>View as Advisor</h1>
        <p>Enter an Advisor ID to view the application as that advisor</p>
        <input
          type="text"
          className='px-2 py-1 rounded'
          value={advisorID}
          onChange={(e) => setAdvisorID(e.target.value)}
          placeholder="Advisor ID"
        />
        <button 
          className='bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors'
          onClick={handleViewAsAdvisor}
        >
          View as Advisor
        </button>
      </div>
    )
  );
};

const App = () => {
  const currentUrl = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUrl.includes('development.whatins.sg') && !currentUrl.includes('localhost') && !currentUrl.includes('http://127.0.0.1:5000/')) {
      navigate('/advisor/profile');
    }
  }, [currentUrl, navigate]);

  if (currentUrl.includes('development.whatins.sg') || currentUrl.includes('localhost') || currentUrl.includes('http://127.0.0.1:5000/')) {
    return (
      <div className='flex flex-row gap-4 mx-auto max-w-screen-2xl'>
      <DeleteComponent />
      <AddComponent />
      <AssignAdvisorsComponent/>
      <ViewAsAdvisorComponent />
    </div> 
    )
  }

  return (
    <div className='flex flex-row gap-4 mx-auto max-w-2xl'>
    </div>
  );
};

export default App;
