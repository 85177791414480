import React, { useEffect, useState } from 'react';
import { zodResolver } from "@hookform/resolvers/zod"
import Image  from "../components/image"
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"
import * as z from "zod"
import Select, { components } from 'react-select';

import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group"
import { Slider } from "../components/ui/slider"
import TypeTag, { getMatchedType } from '../components/TypeTag';
import { PremiumsOnUsFAQ } from './lp-get-proposals';

import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "../components/ui/command"


import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form"

const formSchema = z.object({
  alias: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  })
})

export function ProfileForm() {
  // 1. Define your form.
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      alias: "",
      email: "",
    },
  })
 
  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className='w-full grid gird-cols-1 md:grid-cols-2 md:space-x-8'>
        <FormField
          control={form.control}
          name="alias"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Alias</FormLabel>
              <FormControl>
                <Input placeholder="Xiao Ming" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Your Best Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        </div>
        <Button type="submit" className='bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full'>GIVE ME ADVICE</Button>
      </form>
    </Form>
  )
}

interface OptionProps {
  htmlFor: string;
  path: string;
  text: string;
}

interface TypeItem {
  id: string;
  fields: {
    'Image Source': string;
    'Questionnaire Name': string;
  };
}

interface ProductItem {
  id: string;
  fields: {
    'Name': string;
    'Insurance Company': string;
    'Insurance Type': string;
  };
}

interface ProductOption {
  value: string;
  label: string;
  product: {
    fields: {
      Name: string;
      // Add other necessary properties from the product object
    };
  };
}

interface Product {
  fields: {
    Name: string;
    // Add other necessary properties from the product object
  };
}

interface InsuranceTypes {
  [key: string]: Product[];
}


const Option: React.FC<OptionProps> = ({ htmlFor, path, text }) => {
  return (
    <label 
      htmlFor={htmlFor} 
      className="inline-flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <Image src={path} alt={text} className='items-center' imgClass='max-h-12'/>
      <div className="text-gray-500 text-center pt-4">{text}</div>
    </label>
  );
};

type Coverage = {
  [key: string]: {
    has: boolean;
    inputMethod?: 'self' | 'guide';
    policy?: { value: string; label: string } | null;
    customPolicy?: string;
    brand?: { value: string; label: string } | null;
  };
};

const Questionnaire = () => {
  const location = useLocation();
  const locationData = location.state?.data;
  const [page, setPage] = useState(locationData?.page);
  const [userId, setUserId] = useState(locationData?.id || '');
  const [isSingaporean, setIsSingaporean] = useState<boolean | null>(null);
  const [isMedical, setIsMedical] = useState<boolean | null>(null);
  const [haveInsurance, setHaveInsurance] = useState<boolean | null>(null);
  const [property, setProperty] = useState<boolean | null>(null);
  const [types, setTypes] = useState<TypeItem[]>([]);
  const [products, setProducts] = useState({});
  const [selections, setSelections] = useState([{ productId: '', productName: '', coverage: '', premium: '' }]);
  const [hasInsurance, setHasInsurance] = useState(false);
  const [insuranceTypes, setInsuranceTypes] = useState<InsuranceTypes>({});
  const [coverage, setCoverage] = useState<{[key: string]: any}>({});
  const [brands, setBrands] = useState<string[]>([]);

  const navigate = useNavigate();
  const { id } = useParams();


  const handleSingaporeanChange = (value: string) => {
    setIsSingaporean(value === 'singaporeanNo');
  };

  const handleMedicalChange = (value: string) => {
    setIsMedical(value === 'medicalYes');
  };

  const handleHaveInsurance = (value: string) => {
    setHaveInsurance(value === 'insuranceYes');
  };

  const handleProperty = (value: string) => {
    setProperty(value === 'propertyYes');
  };

  useEffect(() => {
    // Parse the query string
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); // Get the 'id' query parameter

    if (id) {
      fetch(`/api/prospect-continue?id=${id}`,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then(response => response.json())
        .then(data => {
          setUserId(id);
          setPage(data.page);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [location]); // Depend on `location` to re-run the effect when the URL changes


  useEffect(() => {
    if (locationData && locationData.page !== undefined) {
      setPage(locationData.page);
      setUserId(locationData.id);
    }
  }, [locationData]);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await fetch('/api/get-types');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTypes(data); // Adjust this line based on the structure of `data`
      } catch (error) {
        console.error('Error fetching types data:', error);
      }
    };

    fetchTypes();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('/api/get-products');
        const data = await response.json();
        setProducts(data.products);
        setBrands(data.brands);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    fetchProducts();
  }, []);

  const handleInsuranceChange = (e) => {
    setHasInsurance(e.target.checked);
    if (!e.target.checked) {
      setCoverage({});
    }
  };

  const handleTypeChange = (group: string, has: boolean) => {
    setCoverage(prev => ({
      ...prev,
      [group]: {
        ...prev[group],
        has,
        inputMethod: has ? 'guide' : undefined, // Set initial input method when user selects "Yes"
        policy: has ? null : undefined,
        customPolicy: has ? '' : undefined,
        brand: has ? null : undefined,
      }
    }));
  };

  const handlePolicySelect = (group, selection) => {
    setCoverage(prev => ({
      ...prev,
      [group]: { ...prev[group], policy: selection }
    }));
  };

  const handleCustomPolicy = (group, customPolicy) => {
    setCoverage(prev => ({
      ...prev,
      [group]: { ...prev[group], customPolicy }
    }));
  };

  const handleBrandSelect = (group, brand) => {
    setCoverage(prev => ({
      ...prev,
      [group]: { ...prev[group], brand }
    }));
  };

  const handleCoverageAmountChange = (group: string, value: string) => {
    setCoverage(prevCoverage => ({
      ...prevCoverage,
      [group]: {
        ...prevCoverage[group],
        coverageAmount: value
      }
    }));
  };
  
  const handlePremiumChange = (group: string, value: string) => {
    setCoverage(prevCoverage => ({
      ...prevCoverage,
      [group]: {
        ...prevCoverage[group],
        premium: value
      }
    }));
  };

  const insuranceOrder = [
    'life insurance',
    'integrated shield plan',
    'personal accident',
    'insurance for saving or investing',
    'any other type of insurance'
  ];

  useEffect(() => {
    // Check if page is greater than 6 and if userId is set
    if (page > 6 && userId) {
      navigate(`/prospect/${userId}`); // Redirect if conditions are met
    }
  }, [page, userId, navigate]); // Depend on page, userId, and navigate to ensure the effect runs when any of these change

  const handleBackClick = () => {
    setPage((currentPage: number) => currentPage - 1);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Extract form data
    const formData = new FormData(e.currentTarget);
    const formObject = Object.fromEntries(formData.entries());

    // Parse the coverage data
    if (formObject.coverage) {
      formObject.coverage = JSON.parse(formObject.coverage as string);
    }
  
    // Send the data to /api/handle-submissions
    fetch('/api/handle-submissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formObject),
    }).then((response) => {
      // You can check the response here if needed
    }).catch((error) => {
      console.error('Error submitting form:', error);
    });
  
    // Increment page to show the next set of questions
    setPage((currentPage: number) => currentPage + 1);
  };

  // When user is done questionnaire
  const handleSubmit2 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Extract form data
    const formData = new FormData(e.currentTarget);
    const formObject = Object.fromEntries(formData.entries());
  
    // Send the data to /api/handle-submissions
    fetch('/api/handle-submissions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formObject),
    }).then((response) => {
      navigate(`/prospect/${userId}`);
    }).catch((error) => {
      console.error('Error submitting form:', error);
    });
  
    // Increment page to show the next set of questions
    setPage((currentPage: number) => currentPage + 1);
  };

  const handleProductSelect = (index, productName) => {
    const newSelections = [...selections];
    // Directly use the productName as it will match the <select> option values
    newSelections[index].productName = productName;
    setSelections(newSelections);
  
    // Logic to add a new selection placeholder if needed, as before
    if (index === selections.length - 1 && selections.length < 10) {
      addNewSelection();
    }
  };
  
  
  
  const handleChange = (index, field, value, action ='update') => {
    if (action === 'remove') {
      // Remove the row if the action is 'remove'
      setSelections(prevSelections => {
        const updatedSelections = [...prevSelections];
        updatedSelections.splice(index, 1);
        return updatedSelections;
      });
    } else {
      // Update the field value if the action is not 'remove'
      setSelections(prevSelections => {
        const updatedSelections = [...prevSelections];
        updatedSelections[index] = {
          ...updatedSelections[index],
          [field]: value
        };
        return updatedSelections;
      });
    }
  };
  
  const addNewSelection = () => {
    setSelections([...selections, { productId: '', productName: '', coverage: '', premium: '' }]);
  };

  const handleInputMethodChange = (group: string, method: 'self' | 'guide') => {
    setCoverage(prev => ({
      ...prev,
      [group]: {
        ...prev[group],
        inputMethod: method,
        policy: method === 'self' ? { value: 'custom', label: "I'll type it" } : null,
        customPolicy: method === 'self' ? prev[group]?.customPolicy || '' : null,
      }
    }));
  };

  return (
    <div className='bg-white-1'>
    <div className="dark:bg-gray-900 mx-auto max-w-screen-xl px-4 py-4 md:py-16 sm:px-6 lg:px-8 max-w-lg ">
      {page === null || page === undefined ? (
      <div>Loading...</div>
    ) : (
      <div>
      <div className='pt-6 md:pt-12 pb-8 md:pb-16 max-w-xl mx-auto'>
            <h1 className="primary-h3 text-green-1 dark:text-white text-center">Let’s begin questionnaire! 📝</h1>
            <p className="mt-4 text-gray-500 text-start">
            Discover your ideal insurance coverage with a quick questionnaire in just 5-10 minutes. Get expert advice for free and find options tailored to your needs. Let's make insurance easy together!
            </p>
          </div>


      {page === 1 && (
        <section className="personal mx-auto text-center w-screen-lg">
          

          <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      About You
                  </span>
              </li>
              <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Job
                  </span>
              </li>
              <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  Life Stage
                  </span>
              </li>
              <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Health
                  </span>
              </li>
              <li className="flex items-center">
                  <span className="flex items-center sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Insurance needs
                  </span>
              </li>
          </ol>

          <form onSubmit={handleSubmit} className="mx-auto mb-0 mt-8 max-w-xl space-y-4">
          <input type="hidden" id="userID" name="userID" value={userId} />
          <input type="hidden" id="page" name="page" value={page} />
          <div className="w-full">
              <div className="space-y-8 rounded-lg p-2 w-full mx-auto">
                  <div id="birthday">
                      <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start">Date of birth *</label>
                      <div className='flex flex-row gap-4'>
                      <Input type="number" className='bg-white-2' name="day" placeholder="DD" required/>
                      <Input type="number" className='bg-white-2' name="month" placeholder="MM" required/>
                      <Input type="number" className='bg-white-2' name="year" placeholder="YYYY" required/></div>
                      
                  </div>
                  <div id="gender">
                      <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start">Gender *</label>
                   <ul className="grid w-full gap-6 grid-cols-2">
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="genderMale" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="genderMale" name="gender" value="genderMale" className="peer" required/>
                        <p>Male</p>
                        </label>
                      </li>
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="genderFemale" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="genderFemale" name="gender" value="genderFemale" className="peer" required/>
                        <p>Female</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div id="singaporean">
                    <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start">Are you Singaporean 🇸🇬? *</label>
                    <ul className="grid w-full gap-6 grid-cols-2">
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="singaporeanYes" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="singaporeanYes" name="singaporean" value="singaporeanYes" className="peer" required onChange={() => handleSingaporeanChange('singaporeanYes')}/>
                        <p>Yes</p>
                        </label>
                      </li>
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="singaporeanNo" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="singaporeanNo" name="singaporean" value="singaporeanNo" className="peer" required onChange={() => handleSingaporeanChange('singaporeanNo')}/>
                        <p>No</p>
                        </label>
                      </li>
                    </ul>
                  </div>

                  {isSingaporean && (
                    <div>
                    <div id="sg-conditional">
                      <div className="sm:col-span-2">
                        <label htmlFor="pass" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">What pass are you holding?</label>
                        <select name="pass" id="pass" className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-xs px-4 py-2 border mb-2">
                          <option selected disabled>Please select</option>
                          <option value="Dependent Pass">Dependent Pass</option>
                          <option value="Employment Pass">Employment Pass</option>
                          <option value="Long Term Visit Pass">Long Term Visit Pass</option>
                          <option value="PR">PR</option>
                          <option value="S Pass">S Pass</option>
                        </select>
                      </div>
                    </div>
                  
                    <div id="live-sg" className="sm:col-span-2">
                        <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start">Are you currently residing in Singapore?</label>
                    <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
                        <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="resideYes" className="flex items-center gap-4 cursor-pointer">
                          <input type="radio" id="resideYes" name="reside" value="resideYes" className="peer"/>
                          <p>Yes</p>
                          </label>
                        </li>
                        <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="resideNo" className="flex items-center gap-4 cursor-pointer">
                          <input type="radio" id="resideNo" name="reside" value="resideNo" className="peer"/>
                          <p>No</p>
                          </label>
                        </li>
                      </ul>
                    </div>
                    </div>
                  )}
                  <div id="hobbies">
                    <label htmlFor="intro" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white text-start">Do you participate in any high-risk hobbies? *</label>
                    <p className="my-2 text-gray-500 text-xs text-start">Some of the most common high-risk hobbies include skydiving, rock climbing, and motor racing etc.</p>
                    <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
                    <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="hobbiesYes" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="hobbiesYes" name="hobbies" value="hobbiesYes"  className="peer" required/>
                        <p>Yes</p>
                        </label>
                      </li>
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="hobbiesNo" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="hobbiesNo" name="hobbies" value="hobbiesNo"  className="peer" required/>
                        <p>No</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div id="travel">
                    <div className="sm:col-span-2">
                      <label htmlFor="travel" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">How often do you travel? *</label>
                      <p className="mt-4 text-gray-500 text-xs text-start">This could be for personal leisure and/or commitments, or for work. </p>
                      <select name="travel" id="travel" className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-xs p-3 border" required>
                        <option selected disabled>Please select</option>
                        <option value="travelWeekly">Once a week</option>
                        <option value="travelMonthly">Once a month</option>
                        <option value="travelQuarterly">Once every few months</option>
                        <option value="travelYearly">Once a year</option>
                        <option value="travelRarely">Very rarely</option>
                      </select>
                    </div>
                  </div>   
              </div>   
          </div>
            <button type="submit" className="primary-button2">Next</button>
          </form>
        </section>
      )}

      {page === 2 && (
        <div className="career mx-auto text-center">
          <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      About You
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Job
                  </span>
              </li>
              <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  Life Stage
                  </span>
              </li>
              <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Health
                  </span>
              </li>
              <li className="flex items-center">
                  <span className="flex items-center sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Insurance needs
                  </span>
              </li>
          </ol>
          
          <form onSubmit={handleSubmit} className="mx-auto mb-0 mt-8 max-w-lg space-y-4">
          <input type="hidden" id="userID" name="userID" value={userId} />
          <input type="hidden" id="page" name="page" value={page} />
          <div className="w-full">
            <div className="space-y-4 rounded-lg p-2 w-full mx-auto">
                <div id="occupation">
                    <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">What is your job or profession? *</label>
                    <input type="text" name= "occupation"  className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2" placeholder='Your job or occupation' required/>
                    <p className="mt-4 text-gray-500 text-xs text-start">Knowing your occupation helps us determine the level of physical risk associated with your job. This can impact the insurance you need for your everyday safety.</p>
                </div>
                <div id="income">
                  <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">What is your <span className='text-semibold'>monthly income</span>? *</label>
                  <input name="income" type="number" className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2" placeholder='Monthly income before taxes in SGD'required/>
                  <p className="mt-4 text-gray-500 text-xs text-start">This information helps advisors calculate the coverage that best fits your needs, while also considering your budget.</p>
                </div>
                <div className='flex justify-between'>
                  <button type="button" onClick={handleBackClick} className="secondary-button1">Back</button>
                  <button type="submit" className="primary-button2">Next</button>
                </div>
            </div>
            </div>
          </form>
        </div>
      )}

      {page === 3 && (
        <div className="career mx-auto text-center">
          <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      About You
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Job
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                  Life Stage
                  </span>
              </li>
              <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Health
                  </span>
              </li>
              <li className="flex items-center">
                  <span className="flex items-center sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Insurance needs
                  </span>
              </li>
          </ol>

          <form onSubmit={handleSubmit} className="mx-auto mb-0 mt-8 max-w-xl space-y-4">
          <input type="hidden" id="userID" name="userID" value={userId} />
          <input type="hidden" id="page" name="page" value={page} />
          <div  className="w-full">
            <div  className="space-y-8 rounded-lg p-2 w-full mx-auto">
                <div id="relationship">
                    <label htmlFor="intro"  className="block mb-2 font-medium text-gray-900 dark:text-white text-start">Are you currently... ? *</label>
                  <ul className="grid w-full gap-6 grid-cols-3 justify-items-center">
                    <li className="w-full gap-4 flex flex-row items-center">
                    <label htmlFor="relationshipSingle" className="flex items-center gap-4 cursor-pointer">
                      <input type="radio" id="relationshipSingle" name="relationship" value="relationshipSingle"  className="peer" required/>
                      <p>Single 👤</p>
                      </label>
                    </li>
                    <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="relationshipRelationship" className="flex items-center gap-4 cursor-pointer">
                      <input type="radio" id="relationshipRelationship" name="relationship" value="relationshipRelationship"  className="peer" required/>
                      <p>In a relationship 💑</p>
                      </label>
                    </li>
                    <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="relationshipMarried" className="flex items-center gap-4 cursor-pointer">
                      <input type="radio" id="relationshipMarried" name="relationship" value="relationshipMarried"  className="peer" required/>
                      <p>Married 💍</p>
                      </label>
                    </li>
                  </ul>
                </div>
                <div id="current-kids">
                  <label htmlFor="currentKids"  className="block mb-2 font-medium text-gray-900 dark:text-white text-start">Do you have any children? If yes, please list their ages. *</label>
                  <input name="currentKids" type="text"  className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2" placeholder="Enter ages if you have children (e.g., 2, 4, 7)" required/>
                  <p className="mt-4 text-gray-500 text-xs text-start">If you don’t have children, enter 0</p>
              </div>
              <div id="how-many">
                <label htmlFor="moreKids"  className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Do you plan on having (more) children in the future? If yes, when would you ideally like to have them? *</label>
                <input name="moreKids" type="text"  className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2" placeholder="Indicate your preferred timing for having (more) children" required/>
                <p  className="mt-4 text-gray-500 text-xs text-start">This would help advisors in determining the most suitable duration for your insurance coverage.</p>
            </div>
            <div id="dependants">
              <label htmlFor="intro"  className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Do you currently financially support any family members or dependents, or anticipate doing so in the future? *</label>
              <textarea name="dependants" className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border" placeholder='For example, if you have children who rely on your financial support or plan to support aging family members, please specify.' rows={3} required></textarea>
              <p  className="mt-4 text-gray-500 text-xs text-start">By providing this information and an estimated timeframe, advisors can ensure it's factored into your coverage decisions.</p>
          </div>
          <div id="property">
              <label htmlFor="intro"  className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Do you have plans to buy a property? If yes, when do you think you might do so? *</label>
              <textarea name="property" className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border" placeholder='Tell us when you plan to buy, such as within a year or a few years, and how much you expect it to be worth. It helps us understand your future goals and how to insure for it.' rows={3} required></textarea>
          </div>
          <div id="aspirations">
              <label htmlFor="intro"  className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Are there future financial goals or aspirations that advisors should consider when suggesting insurance options? *</label>
              <textarea name="aspirations" className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border" placeholder='If not applicable, leave this field empty.' rows={3} required></textarea>
              <p  className="mt-4 text-gray-500 text-xs text-start">We want to ensure all your plans, like starting a business or further education, are taken into account.</p>
          </div>
            </div>
            <div className='flex justify-between'>
              <button type="button" onClick={handleBackClick} className="secondary-button1">Back</button>
              <button type="submit" className="primary-button2">Next</button>
            </div>
        </div>
          </form>
        </div>
      )}

      {page === 4 && (
        <div className="career mx-auto text-center">
          <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      About You
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Job
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                  Life Stage
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Health
                  </span>
              </li>
              <li className="flex items-center">
                  <span className="flex items-center sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      Insurance needs
                  </span>
              </li>
          </ol>
          

          <form onSubmit={handleSubmit} className="mx-auto mb-0 mt-8 max-w-lg space-y-4">
          <input type="hidden" id="userID" name="userID" value={userId} />
          <input type="hidden" id="page" name="page" value={page} />
          <div className="w-full">
            <div className="space-y-4 rounded-lg p-2 w-full mx-auto">
              <div id="smoker">
                <label htmlFor="intro" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white text-start">Are you a smoker? *</label>
                <ul className="grid w-full gap-6 grid-cols-2">
                  <li className="w-full gap-4 flex flex-row items-center">
                  <input type="radio" id="smokerYes" name="smoker" value="smokerYes" className="peer" required/>
                  <p>Yes 🚬</p>
                </li>
                <li className="w-full gap-4 flex flex-row items-center">
                  <input type="radio" id="smokerNo" name="smoker" value="smokerNo" className="peer" required/>
                  <p>No 🚭</p>
                </li>
              </ul>
            </div>                 

            <div id="explain-medical">
              <label htmlFor="explain" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Do you have any medical conditions or past medical issues?</label>
              <textarea className="w-full rounded-lg border-gray-200 p-3 text-xs border" rows={3} name="Include any medical conditions you currently have or have had in the past" required></textarea>
              <p className="mt-4 text-gray-500 text-xs text-start">If you've sought insurance coverage for this issue in the past, let us know.</p>
            </div>
            </div>
            <div className='flex justify-between'>
              <button type="button" onClick={handleBackClick} className="secondary-button1">Back</button>
              <button type="submit" className="primary-button2">Next</button>
            </div>
        </div>
          </form>
        </div>
      )}

      {page === 5 && (
        <div className="career mx-auto text-center">
          <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      About You
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Job
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                  Life Stage
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Health
                  </span>
              </li>
              <li className="flex items-center text-green-2">
                <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Insurance needs
                  </span>
              </li>
          </ol>

          <form onSubmit={handleSubmit} className="mx-auto mb-0 mt-8 max-w-xl space-y-4">
          <input type="hidden" id="userID" name="userID" value={userId} />
          <input type="hidden" id="page" name="page" value={page} />
          <input type="hidden" id="coverage" name="coverage" value={JSON.stringify(coverage)} />
<div className="w-full *:py-2">
  <div id="rate">
    <label htmlFor="rate" className="block mb-2 font-medium text-gray-900 dark:text-white text-start">What is your level of understanding regarding insurance? *</label>
  <ul className="grid w-full gap-4 *:text-start *:text-xs">
  <li className="w-full gap-4 flex flex-row items-center">
    <label htmlFor="no_knowledge" className="flex items-center gap-4 cursor-pointer">
      <input type="radio" id="no_knowledge" name="insurance_status" value="no_knowledge" className="peer" required/>
      <p>No knowledge or experience with insurance</p>
      </label>
    </li>
    <li className="w-full gap-4 flex flex-row items-center">
    <label htmlFor="beginner" className="flex items-center gap-4 cursor-pointer">
      <input type="radio" id="beginner" name="insurance_status" value="beginner" className="peer" required/>
      <p>Beginner - Limited understanding of insurance concepts</p>
      </label>
    </li>
    <li className="w-full gap-4 flex flex-row items-center">
    <label htmlFor="intermediate" className="flex items-center gap-4 cursor-pointer">
      <input type="radio" id="intermediate" name="insurance_status" value="intermediate" className="peer" required/>
      <p>Intermediate - Some knowledge of insurance, but still learning</p>
      </label>
    </li>
    <li className="w-full gap-4 flex flex-row items-center">
    <label htmlFor="advanced" className="flex items-center gap-4 cursor-pointer">
      <input type="radio" id="advanced" name="insurance_status" value="advanced" className="peer" required/>
      <p>Advanced - Strong understanding of insurance principles</p>
      </label>
    </li>
    <li className="w-full gap-4 flex flex-row items-center">
    <label htmlFor="expert" className="flex items-center gap-4 cursor-pointer">
      <input type="radio" id="expert" name="insurance_status" value="expert" className="peer" required/>
      <p>Expert - Extensive experience and expertise in insurance matters</p>
      </label>
    </li>
    
  </ul>
</div>
<div id="existing-ins" className="career mx-auto text-center">
  <div className="w-full">
    <div className="space-y-4 rounded-lg p-2 w-full mx-auto">
      <div id="has-insurance">
        <label htmlFor="hasInsurance" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white text-start">Do you already have insurance? *</label>
        <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
          <li className="w-full gap-4 flex flex-row items-center text-sm">
            <label htmlFor="hasInsuranceYes" className="flex items-center gap-4 cursor-pointer">
              <input 
                type="radio" 
                id="hasInsuranceYes" 
                name="hasInsurance" 
                value="yes" 
                checked={hasInsurance} 
                onChange={() => setHasInsurance(true)} 
                className="peer" 
                required
              />
              <p>Yes</p>
            </label>
          </li>
          <li className="w-full gap-4 flex flex-row items-center text-sm">
            <label htmlFor="hasInsuranceNo" className="flex items-center gap-4 cursor-pointer">
              <input 
                type="radio" 
                id="hasInsuranceNo" 
                name="hasInsurance" 
                value="no" 
                checked={!hasInsurance} 
                onChange={() => setHasInsurance(false)} 
                className="peer" 
                required
              />
              <p>No</p>
            </label>
          </li>
        </ul>
      </div>

      {hasInsurance && (
        <div className="mt-4">
          <label htmlFor="inputMethod" className="block mb-2 text-gray-900 dark:text-white text-start">How would you like to input the details? *</label>
          <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
            <li className="w-full gap-4 flex flex-row items-center">
              <label htmlFor="inputMethodSelf" className="flex items-center gap-4 cursor-pointer text-xs text-start">
                <input 
                  type="radio" 
                  id="inputMethodSelf" 
                  name="inputMethod" 
                  value="self" 
                  checked={coverage.inputMethod === 'self'} 
                  onChange={() => setCoverage(prev => ({ ...prev, inputMethod: 'self' }))} 
                  className="peer" 
                  required
                />
                <p>I'll input the details myself</p>
              </label>
            </li>
            <li className="w-full gap-4 flex flex-row items-center">
              <label htmlFor="inputMethodGuide" className="flex items-center gap-4 cursor-pointer text-xs">
                <input 
                  type="radio" 
                  id="inputMethodGuide" 
                  name="inputMethod" 
                  value="guide" 
                  checked={coverage.inputMethod === 'guide'} 
                  onChange={() => setCoverage(prev => ({ ...prev, inputMethod: 'guide' }))} 
                  className="peer" 
                  required
                />
                <p>Use a guide</p>
              </label>
            </li>
          </ul>
        </div>
      )}

      {hasInsurance && coverage.inputMethod === 'self' && (
        <div className="mt-4">
          <textarea
            className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
            placeholder="Please describe your insurance policies (include coverage amount and monthly/annual premium if possible)"
            value={coverage.customPolicy || ''}
            onChange={(e) => setCoverage(prev => ({ ...prev, customPolicy: e.target.value }))}
          />
        </div>
      )}

{hasInsurance && coverage.inputMethod === 'guide' && insuranceOrder.map((group) => (
  <div key={group} id={`insurance-${group}`} className="mt-4">
    <label htmlFor={`has${group}`} className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white text-start">Do you have {group}? *</label>
    <ul className="grid w-full gap-6 grid-cols-2 justify-items-center">
      <li className="w-full gap-4 flex flex-row items-center">
        <label htmlFor={`has${group}Yes`} className="flex items-center gap-4 cursor-pointer text-xs">
          <input 
            type="radio" 
            id={`has${group}Yes`} 
            name={`has${group}`} 
            value="yes" 
            checked={coverage[group]?.has === true} 
            onChange={() => handleTypeChange(group, true)} 
            className="peer text-xs" 
            required
          />
          <p>Yes</p>
        </label>
      </li>
      <li className="w-full gap-4 flex flex-row items-center">
        <label htmlFor={`has${group}No`} className="flex items-center gap-4 cursor-pointer text-xs">
          <input 
            type="radio" 
            id={`has${group}No`} 
            name={`has${group}`} 
            value="no" 
            checked={coverage[group]?.has === false || coverage[group]?.has === undefined} 
            onChange={() => handleTypeChange(group, false)} 
            className="peer text-xs" 
            required
          />
          <p>No</p>
        </label>
      </li>
    </ul>

    {coverage[group]?.has && products[group] && (
      <div className="mt-4">
        <label htmlFor={`policy${group}`} className="block mb-2 text-sm text-gray-900 dark:text-white text-start">What policy do you have? *</label>
        <Select
          name={`policy${group}`}
          value={coverage[group]?.policy}
          onChange={(selectedOption) => handlePolicySelect(group, selectedOption)}
          options={[
            { value: 'custom', label: "I'll type it" },
            { value: 'unknown', label: "I don't know" },
            ...(products[group] as Product[]).map((product: Product) => ({
              value: product.fields.Name,
              label: product.fields.Name,
              product: product,
            })),
          ]}
          placeholder="Select a policy (can search)"
          className="basic-single w-full text-xs text-start"
        />
              {coverage[group]?.policy?.value === 'custom' && (
                <textarea
                  className="mt-2 w-full rounded-lg border-gray-200 align-top shadow-sm sm:text-xs p-3 border"
                  placeholder="Please describe your insurance policies (include coverage amount and monthly/annual premium if possible)"
                  value={coverage[group]?.customPolicy || ''}
                  onChange={(e) => handleCustomPolicy(group, e.target.value)}
                />
              )}

              {coverage[group]?.policy?.value === 'unknown' && (
                <div className="mt-4">
                  <label htmlFor={`brand${group}`} className="block mb-2 text-sm text-gray-900 dark:text-white text-start">That's fine. Do you know what company?</label>
                  <Select name={`brand${group}`}
                    value={coverage[group]?.brand}
                    onChange={(selectedOption) => handleBrandSelect(group, selectedOption)}
                    options={[
                      { value: 'unknown', label: "I don't know" },
                      ...brands.map((brand) => ({ value: brand, label: brand })),
                    ]}
                    placeholder="Select a company"
                    className="basic-single w-full text-sm text-start"
                  />
                </div>
              )}

              {coverage[group]?.policy?.value && 
               coverage[group]?.policy?.value !== 'custom' && 
               coverage[group]?.policy?.value !== 'unknown' && 
               coverage[group]?.brand?.value !== 'unknown' && (
                <div className="mt-4 space-y-4">
                  <div>
                    <label htmlFor={`coverage${group}`} className="block mb-2 text-sm text-gray-900 dark:text-white text-start">What is the coverage amount?</label>
                    <input
                      type="text"
                      id={`coverage${group}`}
                      name={`coverage${group}`}
                      className="w-full rounded-lg border-gray-200 p-3 text-xs border"
                      placeholder="Enter coverage amount"
                      value={coverage[group]?.coverageAmount || ''}
                      onChange={(e) => handleCoverageAmountChange(group, e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor={`premium${group}`} className="block mb-2 text-sm text-gray-900 dark:text-white text-start">How much are you paying?</label>
                    <input
                      type="text"
                      id={`premium${group}`}
                      name={`premium${group}`}
                      className="w-full rounded-lg border-gray-200 p-3 text-xs border"
                      placeholder="Enter premium amount"
                      value={coverage[group]?.premium || ''}
                      onChange={(e) => handlePremiumChange(group, e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
</div>


    <div id="budget" className='py-2'>
        <label htmlFor="budget" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Do you have a monthly budget for insurance? *</label>
      <input name ="budget" type="number" className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2" placeholder="If you don't have a specific budget, you can enter 0" required/>
        <p className="mt-4 text-gray-500 text-xs text-start">Consider keeping insurance costs within 10% of your income for optimal balance. However, if you view insurance as an investment, your approach may differ.</p>
    </div>
    <div className='flex justify-between'>
                  <button type="button" onClick={handleBackClick} className="secondary-button1">Back</button>
                  <button type="submit" className="primary-button2">Next</button>
                </div>
          </div>
          </form>
        </div>
      )}

      {page === 6 && (
        <div id="page-6" className="future mx-auto text-center">
        <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base *:text-xs *:md:text-base max-w-xl mx-auto">
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      About You
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                  <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Job
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                  Life Stage
                  </span>
              </li>
              <li className="flex md:w-full items-center text-green-1 dark:text-green-1 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Health
                  </span>
              </li>
              <li className="flex items-center text-green-2">
                <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-500 dark:after:text-gray-500">
                      <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5 hidden md:block" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                      </svg>
                      Insurance needs
                  </span>
              </li>
          </ol>
    
        <form onSubmit={handleSubmit2} className="mx-auto mb-0 mt-8 max-w-lg space-y-4">
          <input type="hidden" id="userID" name="userID" value={userId} />
          <input type="hidden" id="page" name="page" value={page} />
              <div className="w-full">
                  <div className="space-y-4 rounded-lg p-2 w-full *:py-2">
                  <div id="what-ins">
                      <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">Select all the types of insurance you're interested in: *</label>
                    <p className="mt-4 text-gray-500 text-xs text-start pb-6">If you have any of the listed plans but want to explore more cost-effective options, feel free to select them! We're here to find the best coverage for you.</p>
                    <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {types.map((type, index) => (
                        <li key={index} className="bg-white-2 border-2 border-gray-200 rounded-lg shadow-md has-[:checked]:border-blue-600 has-[:hover]:bg-gray-50">
                          <input type="checkbox" name={type.id} id={type.id} value="checked" className="hidden peer"/>
                          <label htmlFor={type.id} className="flex flex-col items-center justify-between w-full p-5 text-gray-500 bg-white rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700  hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <div className="block col-span-1/2 md:col-span-1/3 *:flex *:justify-center">
                              {/* Adjust the image source and alt text as needed */}
                              <Image src={`${type.fields['Image Source']}.svg`} alt={type.fields['Questionnaire Name']} />
                              <div className="text-gray-500 text-center">{type.fields['Questionnaire Name']}</div>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>
                    </div>
                    <div id="insurance_expectation">
                      <label htmlFor="intro" className="block mb-2 font-medium text-gray-900 dark:text-white text-start">And lastly, what are you hoping from Whatins?</label>
                   <ul className="grid w-full gap-4">
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="want_full_review" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="want_full_review" name="insurance_expectation" value="want_full_review" className="peer" required/>
                        <p>I want a full review of my financial situation.</p>
                        </label>
                      </li>
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="looking_for_quote" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="looking_for_quote" name="insurance_expectation" value="looking_for_quote" className="peer" required/>
                        <p>I know what I want. Just need quotes.</p>
                        </label>
                      </li>
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="have_question_about_specific_policy" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="have_question_about_specific_policy" name="insurance_expectation" value="have_question_about_specific_policy" className="peer" required/>
                        <p>I have questions about a specific policy.</p>
                        </label>
                      </li>
                      <li className="w-full gap-4 flex flex-row items-center">
                      <label htmlFor="looking_for_advice" className="flex items-center gap-4 cursor-pointer">
                        <input type="radio" id="looking_for_advice" name="insurance_expectation" value="looking_for_advice" className="peer" required/>
                        <p>I'm looking for advice.</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div id="number">
                    <label htmlFor="number" className="block mb-2 font-medium text-gray-900 dark:text-white text-start ">[Optional] What is your number?</label>
                    <input type="text" name= "number"  className="w-full rounded-lg border-gray-200 p-3 text-xs border mt-2" placeholder='Share & get additional entry to Premiums On Us'/>
                    <p className="mt-4 text-gray-500 text-xs text-start">By filling this field out, we will credit you with one additional lifetime entry to Premiums On Us. We will only share this with the advisor once they share over their proposal (when they get your email).</p>
                    <PremiumsOnUsFAQ triggerText="Learn more about Premiums On Us" className="primary-link" />

                </div>
                  <div className='flex justify-between'>
                  <button type="button" onClick={handleBackClick} className="secondary-button1">Back</button>
                    <button type="submit" className="primary-button2">Complete!</button>
                  </div>
                    
                    </div>
                      
                  </div>
          </form>
      </div>
      )}
      </div>
    )}
    </div>
    </div>
  );
};


export default Questionnaire;