"use client"
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"
import posthog from 'posthog-js'
import { useToast } from "../components/ui/use-toast"
import {PremiumsOnUsFAQ} from "./lp-get-proposals"
import { reportError } from '../components/reportError';

type DynamicFields = {
  [key: string]: string | number | boolean | null; // Assuming simple scalar values, adjust as necessary
};
interface Prospect {
  fields?: DynamicFields;
}

interface ProspectFields {
  [key: string]: any; // Adjust based on your actual prospect fields
}

const prepareFormData = (prospectFields: ProspectFields): {[key: string]: string | boolean} => {
  const formData: {[key: string]: string | boolean} = {};
  Object.keys(prospectFields).forEach(key => {
    const value = prospectFields[key];
    if (typeof value === 'boolean') {
      // For boolean values, keep them as is (useful for checkboxes)
      formData[key] = value;
    } else {
      // Convert everything else to string
      formData[key] = String(value || '');
    }
  });
  return formData;
};

const ProspectCentre = () => {
  const [advisor, setAdvisors] = useState([]);
  const [prospect, setProspect] = useState<Prospect | null>(null);
  const location = useLocation();
  const [selectedAdvisorIndex, setSelectedAdvisorIndex] = useState(null);
  const { toast } = useToast()
  const [formData, setFormData] = useState({});
  const [comments, setComments] = useState('');
  const [countdown, setCountdown] = useState(0); 

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
  
    // Start the countdown regardless of message content
    setCountdown(5);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  
    // Check if the message is empty or only contains whitespace
    if (!comments.trim()) {
      toast({
        title: 'Empty message',
        description: "We assume that was a mistake. No message was sent.",
      });
      return;
    }
  
    if (countdown > 0) {
      toast({
        title: 'Please wait',
        description: `You can send another message in ${countdown} seconds.`,
      });
      return;
    }
  
    const formData = {
      recordID: advisor[selectedAdvisorIndex].data.fields['recordID'],
      action: 'send-message',
      message: comments,
    };
  
    try {
      const response = await fetch('/api/prospect-action', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setComments('');
        toast({ 
          title: 'Message sent',
          description: "Your message was just sent!"
        });
        posthog.capture('prospect_send_message');
      } else {
        toast({
          title: 'Something went wrong',
          description: "Please email us about it. Sorry about that 🙏",
          variant: 'destructive',
        });
      }
    } catch (error) {
      reportError({
        message: 'Error sending message',
        url: window.location.href,
        function: 'handleMessageSubmit',
        componentName: 'ProspectCentre',
        additionalInfo: { advisorIndex: selectedAdvisorIndex },
        stackTrace: error.stack
      });
      toast({
        title: 'Network error',
        description: "Please check your internet connection and try again.",
        variant: 'destructive',
      });
    }
  };

  const extractPath = () => {
    const basePath = '/prospect/';
    const fullPath = location.pathname;
    return fullPath.includes(basePath) ? fullPath.split(basePath)[1] : '';
  };

  const id = extractPath();

  useEffect(() => {
  
    const fetchData = async (isReduced = true) => {
      try {
        const params = new URLSearchParams();
        const productParam = id || "";
        if (productParam) params.append('id', productParam);
  
        // Append isReduced parameter to the request
        params.append('reduced', isReduced.toString());

        const response = await fetch(`/api/prospect?${params}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        posthog.capture('prospect_on_dashboard')
        setAdvisors(data.advisors || []);
        setProspect(data.user || {});
        setFormData(prepareFormData(prospect.fields));
        setSelectedAdvisorIndex(0)
        console.log(data)
      } catch (error) {
        reportError({
          message: 'Error fetching types data',
          url: window.location.href,
          function: 'fetchData',
          componentName: 'ProspectCentre',
          additionalInfo: { isReduced },
          stackTrace: error.stack
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (prospect?.fields) {
      setFormData(prepareFormData(prospect.fields));
    }
  }, [prospect]);

  const handleOnboardingSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    const formData = {
      recordID: prospect?.['id'],
      email: prospect?.['fields']?.['Email'],
    };
  
    try {
      const response = await fetch('/code/send-prospect-onboarding', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to application/json
        },
        body: JSON.stringify(formData), // Convert the formData object to a JSON string
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`); // Handle server errors
      }
  
      // Handle success
      toast({
        description: "Verification email sent successfully",
      })
    } catch (error) {
      reportError({
        message: 'Failed to send verification email',
        url: window.location.href,
        function: 'handleOnboardingSubmit',
        componentName: 'ProspectCentre',
        additionalInfo: { prospectId: prospect?.['id'] },
        stackTrace: error.stack
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      // Directly use the name attribute value, including spaces and casing
      const updatedFormData = { ...prevFormData, [name]: value };
      return updatedFormData;
    });
};
  
  

const handleProfileSubmit = async () => {
  
    try {
      const response = await fetch('/api/prospect-update-details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recordID: prospect?.['fields']['recordID'],
          fields: formData
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`); // Handle server errors
      }
  
      // Handle success
      toast({
        description: "Information updated successfully",
      })
    } catch (error) {
      reportError({
        message: 'Failed to update profile',
        url: window.location.href,
        function: 'handleProfileSubmit',
        componentName: 'ProspectCentre',
        additionalInfo: { prospectId: prospect?.['fields']['recordID'] },
        stackTrace: error.stack
      });
    }
  };
  
  return (
    <div className='bg-white-1'>
  <div >
    <section className="grid max-w-screen-xl mx-auto py-8 dark:text-white">
      <div className="text-center">
          <h1 className="primary-h3 text-green-1">{prospect?.['fields']?.['Name']}'s Dashboard</h1>
          <p className="">You can update your information here and see your customized advice from the different advisors.</p>
      </div>
    </section>
    <section className="flex flex-col justify-items-center my-4 dark:text-white">
      <div className="grid grid-cols-4 gap-4 max-w-screen-2xl">
        <div id="col1" className="px-2 md:px-8 col-span-4 md:col-span-1 order-last md:order-first">
          <div className="flex flex-col py-2 md:basis-1/4 flex-0">
          {!prospect?.['fields']?.['Telegram ID'] && (
          <div className='flex flex-col bg-blue-1 p-4 rounded-2xl gap-4'>
            <h2 className="text-h5 text-green-1 font-bold text-pretty">Connect to our Telegram Bot 🤖</h2>
            <p>Consider connecting with our Telegram Bot! It's the best way for advisors to <span className='font-semibold'>ask clarification questions</span> while keeping your contact information private. 😊</p> 
            <a className="px-4 py-2 border border-gray-2 rounded-xl shadow-lg hover:bg-green-2 bg-green-1 text-white-1 mx-auto" href={`https://t.me/whatins_bot?start=${prospect?.['id']}`} >Connect Now</a>
          </div>
          )}
         
          <form id='updateProfile' className="p-4" onSubmit={(e) => { e.preventDefault(); handleProfileSubmit(); }}>
          {formData && (
          <Accordion type="single" collapsible>
        <ul className="space-y-4">
            <li><h2 className="font-bold text-xl capitalize primary-h3 text-green-1">Your Profile</h2></li>
            <li><p className="">Missed something? Update it below.</p></li>
            <AccordionItem value="item-1">
            <AccordionTrigger>Personal Details</AccordionTrigger>
            <AccordionContent>
            <li>
              <div className="flex justify-between mb-4 text-xl font-bold  dark:text-white">Name/Alias: <span className="text-right mb-2 text-lg font-light  dark:text-white">{prospect?.['fields']?.['Name']}</span></div>
            </li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Gender: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['Gender']}</span></div></li>
            {prospect?.['fields']?.['Year of Birth'] && (
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Year of Birth:<span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['Year of Birth']}</span></div></li>
            )}
            {prospect?.['fields']?.['Birthday'] && (
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Birthday:<input type="date" id="birthday" name="birthday" className='border px-2 rounded-2xl' 
              onChange={handleInputChange}
              value={prospect?.['fields']?.['Birthday'] ? String(prospect['fields']['Birthday']) : ''}/></div></li>
            )}
            
            
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Singaporean?: <span className="text-right mb-2 text-sm font-light  dark:text-white">
            {prospect?.['fields']?.['Singaporean?'] ? ( <p>Yes</p> ) : ( <p>No</p> )}</span></div></li>
            {!prospect?.['fields']?.['Singaporean?'] && (
              <div>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">What Pass: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['What pass?']}</span></div></li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Live in 🇸🇬?: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['Living in SG?']}</span></div></li>
            </div>
            )}
            </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
            <AccordionTrigger>Job</AccordionTrigger>
            <AccordionContent>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Job/Profession:</div> 
              <input type="text" name= "Occupation"  className="w-full rounded-lg border-gray-200 p-3 text-sm  mb-2 font-light  dark:text-white border" 
              onChange={handleInputChange} 
              value={formData['Occupation'] || ''} required/></li>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Monthly Income:</div> 
              <input name="Monthly Income" // Keep the exact string to match formData keys
                type="number" 
                className="w-full rounded-lg border-gray-200 p-3 text-sm text-right mb-2 font-light dark:text-white border" 
                onChange={handleInputChange} 
                value={formData['Monthly Income'] || ''} // Use the exact key as in prospect.fields
                required/></li>
            </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
            <AccordionTrigger>Life Stage</AccordionTrigger>
            <AccordionContent>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Relationship Status: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['Relationship Status']}</span></div></li>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Have Kids?: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['Current Kids']}</span></div></li>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Future Kids?: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['More Kids In Future']}</span></div></li>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Current / Future Dependants:</div> 
              <textarea rows={3} className="block p-3 w-full text-sm  bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" 
              required name="Other Dependants" onChange={handleInputChange} 
              value={formData['Other Dependants'] || ''}></textarea></li>
            </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-4">
            <AccordionTrigger>Health</AccordionTrigger>
            <AccordionContent>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Smoker?: <span className="text-right mb-2 text-sm font-light  dark:text-white">
              {prospect?.['fields']?.['Smoker?'] ? ( <p>Yes</p> ) : ( <p>No</p> )}</span></div></li>
              <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Medical History?:</div> 
            {prospect?.['fields']?.['Medical?'] ? ( 
              <textarea rows={3} className="block p-3 w-full text-sm  bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" 
              required 
              name='Medical Condition' 
              value={formData['Medical Condition'] || ''}></textarea>
              ) : (
                <p>None</p>
            )}</li>
            </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-5">
            <AccordionTrigger>Insurance Needs</AccordionTrigger>
            <AccordionContent>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">How Well Do You Understand Insurance: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['Understand Insurance']}</span></div></li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">What Insurance Do You Have?</div>
              {prospect?.['fields']?.['Existing Policies'] ? ( 
                <textarea rows={3} className="block p-3 w-full text-sm  bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" required 
                name="Policies" onChange={handleInputChange} 
                value={formData['Existing Policies']}></textarea>
              ) : (
                <textarea rows={2} className="block p-3 w-full text-sm  bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500" required name="explain" placeholder="Please add whatever insurance you have here. Else say 'no insurance'"></textarea>
              )}
              </li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">What Insurance Are You Looking For?</div>
              <ul className="block mb-2 text-sm font-light  dark:text-white">
              {
                Array.isArray(prospect?.['fields']?.['Types Name']) &&
                  prospect['fields']['Types Name'].map((plan, index) => (
                    <li key={index}>{plan}</li>
                  ))
              }

                </ul>
              </li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Ideal Insurance Budget (Monthly):</div> 
            <input name ="Budget" type="number" className="w-full rounded-lg border-gray-200 p-3 text-sm text-right mb-2 font-light  dark:text-white border"  onChange={handleInputChange} 
            value={formData['Budget'] || ''}
            required/></li>
            </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-6">
            <AccordionTrigger>Financial Goals</AccordionTrigger>
            <AccordionContent>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Do you participate in any high risk activities: <span className="text-right mb-2 text-sm font-light  dark:text-white">
            {prospect?.['fields']?.['High Risk Hobbies?'] ? ( <p>Yes</p> ) : ( <p>No</p> )} </span></div></li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">How often do you travel: <span className="text-right mb-2 text-sm font-light  dark:text-white">{prospect?.['fields']?.['How Often Do You Travel?']}</span></div></li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Do you plan on buying property? If so, what's the budget range?:</div> <span className="text-right mb-2 text-sm font-light  dark:text-white">
            {prospect?.['fields']?.['Buy Property?'] ? ( <p>{prospect?.['fields']?.['Ideal Property Budget']}</p> ) : ( <p>No</p> )}  </span></li>
            <li><div className="flex justify-between gap-y-2 mb-2 text-sm font-medium  dark:text-white">Do you have any future financial goals and aspirations that the advisor should be aware of to suggest the perfect insurance fit?</div> 
            <textarea
              rows={2}
              className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
              name="Other Aspirations" // Ensure this matches exactly with the formData key
              value={formData['Other Aspirations'] || ''} // Set the value attribute correctly
              onChange={handleInputChange} // Add the onChange handler to capture user input
            ></textarea>
            </li>
            </AccordionContent>
            </AccordionItem>
          </ul>
          </Accordion>
          )}
          <button type="submit" className="primary-button1 hover:primary-button2">Update Profile</button>
        </form>
          </div>
        </div>
        <div id="col2" className="px-2 md:px-8 col-span-4 md:col-span-3 space-y-2">
        
                    {!prospect?.['fields']?.['Answered Intro Email?'] ? (
            <div className='flex flex-col border-2 border-gray-2 p-4 rounded-2xl gap-4 bg-white-2'>
            <h2 className="text-h5 text-green-1 font-semibold">👉 Verify your email to get started</h2>
            <p>Before getting advisors’ advice, please verify your email. You should have received an email from us titled: <span className='font-italic'>[Quick Reply Needed] Can we find your advisors now </span><b>If you haven't received an email from us</b>, please request it again below.</p>
             <form action="/code/send-prospect-onboarding"  method="post" className="p-2 flex flex-col gap-4 items-center">
              <input type="hidden" name='recordID' value={prospect?.['id']}/>
            <input type="text" name= "email"  className="bg-white-1 w-full rounded-xl" 
            value={prospect?.['fields']?.['Email'] ? String(prospect['fields']['Email']) : 'No email on file'} disabled/>
            <button type="button" onClick={handleOnboardingSubmit} className="primary-button2 text-white ">Send Verification Email</button>
            </form>
          </div>
          ): (
            <div className='flex flex-col border-2 border-gray-2 p-4 rounded-2xl gap-4 bg-white-2'>
            <h2 className="text-h5 text-green-1 font-semibold">{prospect?.['fields']?.['Premiums On Us Entries'] ?? 0} Premiums On Us Entries</h2>
            <p>The Premiums On Us giveaway is our way of rewarding you for taking the time to find the right insurance coverage through our platform.<PremiumsOnUsFAQ className='underline text-green-1 text-start'triggerText="Click here for more information" />
 </p>
             
          </div>
          )}
      <div id="tab" className="flex flex-col md:flex-row gap-4 md:gap-6 justify-start">
  {advisor.map((advisor, index) => (
    <div key={index}> {/* Move the key to the immediate parent in the map */}
      <button
        className={`w-full md:w-auto px-4 py-2 border border-gray-2 rounded-xl shadow-lg hover:bg-green-2 hover:text-green-1 ${selectedAdvisorIndex === index ? 'bg-green-1 text-white-2' : 'bg-white-2'}`} 
        onClick={() => setSelectedAdvisorIndex(index)} // Update the selected index on click
      >
        <div className="capitalize">
          {advisor.data.fields['Advisor Name'][0]}
        </div>
      </button>
    </div>
  ))}
      </div>

      <div className="border md:p-8 rounded-xl shadow-xl bg-white-2 border-gray-2">
      {selectedAdvisorIndex !== null && advisor[selectedAdvisorIndex] && (
  <div className="flex flex-col md:flex-row gap-8">
    <div className="gap-y-8 divide-y-2 md:basis-1/2 order-last md:order-first">
      <div className="p-4 hidden md:block">
        <h2 className="font-bold text-xl capitalize">{advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}</h2>
        <p>Company: {advisor[selectedAdvisorIndex].data.fields['Advisor Company']}</p>
      </div>
      {selectedAdvisorIndex !== null && advisor[selectedAdvisorIndex] && (
  <div className="p-4">
    {advisor[selectedAdvisorIndex].data.fields['send?'] ? (
      <>
        <h2 className="font-abold text-lg capitalize">Their Message</h2>
        <textarea 
          rows={8} 
          disabled 
          className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          value={advisor[selectedAdvisorIndex].data.fields['Additional comments'] || ''}
        />
        <h2 className="font-abold text-lg capitalize pt-6">Files from Advisor (Click to Download)</h2>
        <ul>
          {advisor[selectedAdvisorIndex].attachments?.map((attachment, attIndex) => (
            <li key={`${selectedAdvisorIndex}-${attIndex}`}>
              <button
                className="mb-2 text-sm font-medium text-blue-500 dark:text-white"
                onClick={async () => {
                  try {
                    window.open(attachment.url, '_blank', 'noopener,noreferrer');
                    const response = await fetch('/api/prospect-action', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        recordID: advisor[selectedAdvisorIndex].data.fields['recordID'],
                        action: 'open-proposal',
                      }),
                    });
                    posthog.capture('prospect_open_proposal');
                    if (!response.ok) {
                      console.error('Error sending prospect open request');
                    }
                  } catch (error) {
                    console.error('Error sending prospect open request', error);
                  }
                }}
              >
                {attachment.filename}
              </button>
            </li>
          ))}
        </ul>
        <h2 className="font-abold text-lg capitalize pt-6">What actions do you plan on taking?</h2>
        <div className="mt-4 flex flex-col">
          {['yes_meet', 'no_meet', 'yes_met', 'yes_buy'].map((option) => (
            <button
              key={`${selectedAdvisorIndex}-${option}`}
              className="primary-button2 my-1 hover:primary-button1"
              onClick={async () => {
                try {
                  const response = await fetch('/api/prospect-action', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      recordID: advisor[selectedAdvisorIndex].data.fields['recordID'],
                      action: option,
                    }),
                  });
                  if (response.ok) {
                    toast({ 
                      title: 'Thank you',
                      description: 'We have noted it down.' 
                    });
                  } else {
                    console.error('Error sending prospect action');
                  }
                } catch (error) {
                  console.error('Error sending prospect action', error);
                }
              }}
            >
              {option === 'yes_meet' && `I plan on meeting ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
              {option === 'no_meet' && `I don't plan on meeting ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
              {option === 'yes_met' && `I have met ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
              {option === 'yes_buy' && `I have purchased from ${advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}`}
            </button>
          ))}
        </div>
      </>
    ) : (
      <div>It seems like they haven't sent anything yet</div>
    )}
  </div>
)}
    </div>
    <div className="space-y-8 divide-y-2 md:basis-1/2">
      <div className="p-4 space-y-2">
      <div className="p-4 block md:hidden">
        <h2 className="font-bold text-xl capitalize">{advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]}</h2>
        <p>Company: {advisor[selectedAdvisorIndex].data.fields['Advisor Company']}</p>
      </div>
        <h2 className="font-bold text-xl capitalize">Chat history</h2>
        {
          Array.isArray(advisor[selectedAdvisorIndex].data.fields['Message History']) ? 
            advisor[selectedAdvisorIndex].data.fields['Message History'].map((message, index) => (
              <div key={index} className="w-full gap-2">
                <p
                  className={`${
                    message.sender === advisor[selectedAdvisorIndex].data.fields['Advisor Name'][0]
                      ? 'text-start bg-blue-1 px-2 py-1 rounded-xl text-sm md:text-base'
                      : 'text-end bg-white-2 px-2 py-1 rounded-xl text-sm md:text-base border-gray-2 border-2'
                  }`}
                >
                  {message.message}
                </p>
              </div>
            ))
          : <p className='text-sm md:text-base'>You two haven't messaged each other.</p>
        }

      </div>
      <div className="p-4 space-y-2">
        <h2 className="font-bold text-xl capitalize">Message Centre</h2>
        <p className='text-sm md:text-base'>We suggest you talk to the advisors via telegram as there are fewer bugs. But if you do not use telegram, you can use this message area to message them. It will be sent as an email.</p>
        <form className="space-y-2" onSubmit={handleMessageSubmit}>
          <textarea
            id="message"
            name="message"
            rows={8}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="block p-3 w-full text-sm bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          ></textarea>
          <button
            type="submit"
            className="primary-button1 hover:primary-button2"
            disabled={countdown > 0}
          >
            {countdown > 0 ? `Send Message (${countdown}s)` : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  </div>
)}


    </div>
        </div>
      </div>
    </section>
  </div>
  </div>
  
  )
}

export default ProspectCentre;


