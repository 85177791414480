import React from 'react';
import Image from '../components/image'
import { GetStartedSidebar } from '../components/CTA'
import { MiniExplore } from '../components/explore'

const PersonCard = ({ name, gender, monthlyIncome, coverage, monthlyPremiums, yearPurchased, preexistingCondition, productsPurchased }) => (
  <div className="bg-white rounded-lg p-6 shadow-md w-full sm:w-80">
    <div className="w-20 h-20 bg-gray-300 rounded-full mx-auto mb-4">{/* Add avatar image */}</div>
    <div className="text-left">
      <h3 className="text-xl font-bold mb-2">{name}</h3>
      <div className='flex justify-between '>
        <p className="text-gray-600 font-semibold">Gender:</p>
        <p className="text-gray-600">{gender}</p>
      </div>
      <div className='flex justify-between '>
        <p className="text-gray-600 font-semibold">Monthly Income: </p>
        <p className="text-gray-600">${monthlyIncome}</p>
      </div>
      <div className='flex justify-between '>
        <p className="text-gray-600 font-semibold">Coverage:</p>
        <p className="text-gray-600">${coverage}</p>
      </div>
      <div className='flex justify-between '>
        <p className="text-gray-600 font-semibold">Monthly Premiums:</p>
        <p className="text-gray-600">${monthlyPremiums}</p>
      </div>
      <div className='flex justify-between '>
        <p className="text-gray-600 font-semibold">Year Purchased:</p>
        <p className="text-gray-600">{yearPurchased}</p>
      </div>
        <p className="text-gray-600 font-semibold">Preexisting Condition:</p>
        <p className="text-gray-600">{preexistingCondition}</p>
      <p className="text-gray-600 font-semibold">Products Purchased:</p>
      {productsPurchased.map((product, index) => (
        <span key={index}>{product}{index !== productsPurchased.length - 1 ? ', ' : ''}</span>
      ))}
    </div>
  </div>
);


const Overview: React.FC = () => {

  const people = [
    {
      name: 'PS, 56',
      gender: 'Female',
      monthlyIncome: 3200,
      coverage: 50000,
      monthlyPremiums: 1873,
      yearPurchased: 2023,
      preexistingCondition: 'High Blood Pressure',
      productsPurchased: ['HSBC Life Super', 'Critical Illness'],
    },
    {
      name: 'Jeremy Lin , 37',
      gender: 'Male',
      monthlyIncome: 3500,
      coverage: 500000,
      monthlyPremiums: 160,
      yearPurchased: 2022,
      preexistingCondition: '',
      productsPurchased: ['SingleLife Elite Term', 'Term Life', 'Income Enhanced', 'Incomeshield', 'Hospital (SP)'],
    },
    {
      name: 'D, 40',
      gender: 'Male',
      monthlyIncome: 10000,
      coverage: 250000,
      monthlyPremiums: 320,
      yearPurchased: 2023,
      preexistingCondition: 'slipped disc and a small renal cyst',
      productsPurchased: ['AIA Secure Flexi Term', 'Term Life'],
    },
  ];

  return (
    <div className="py-8 ">
      <div>
      <h2 className="primary-h2">Overview</h2>
        <h2 className="primary-h3 pt-4 pb-2">Policy Term Options</h2>
        <p className="mb-8">
        AIA Secure Flexi Term allows you to select between 2 options for your coverage term. You have the choice of choosing a renewable term of 5, 10, 20, or 30 years, or a level term of up to 65 or 75 years old. You can renew your policy at the end of each term until the age of 101.
        </p>
        <h2 className="primary-h3 pt-4 pb-2">Discounts Available</h2>
        <p className="mb-8">
        You can get a first-year premium discount and subsequent premium discounts of up to 15%, as long as you get healthier and level up your status in your integrated AIA Vitality wellness program. This would further help to make your policy cheaper and even more affordable.
        </p>
        
        
      </div>
      <div>

      <h2 className="primary-h2">Protection Options</h2>
      <p className="mb-8">
        While it might seem like overkill at a first glance, here are some key moments in which purchasing life insurance makes sense:
      </p>
      <ol className="list-decimal list-outside mb-8 pl-4">
        <li>
          <p><strong>Death Benefits.</strong> AIA shall pay the Death Benefit, which is the Insured Amount less any monies owed to AIA, if the Insured passes away while the basic policy is in effect.</p>
        </li>
        <li>
          <p><strong>Terminal Illness.</strong> AIA will pay the Terminal Illness Benefit, which is the Insured Amount less any monies owed to AIA, if the life assured is diagnosed with Terminal Illness while the policy is in effect. The term 'Terminal Illness' refers to a confirmed diagnosis of an illness that is likely to cause the life assured's death within 12 months.
          </p></li>
      </ol>
      </div>
      <div>
      <h2 className="primary-h2">AIA Secure Flexi Term Riders</h2>
      <p className="mb-8">
        While it might seem like overkill at a first glance, here are some key moments in which purchasing life insurance makes sense:
      </p>
      <ol className="list-decimal list-outside mb-8 pl-4">
        <li>
          <p><strong>Critical Illness.</strong> The Critical Cover rider provides coverage for 43 major critical illnesses.</p>
        </li>
        <li>
          <p><strong>Early Critical Cover.</strong> The Early Critical Cover rider adds protection for 32 early and 29 intermediate critical illnesses, as well as 5 special conditions.</p>
        </li>
        <li>
          <p><strong>Total and Permanent Disability.</strong> The Total and Permanent Disability (TPD) rider provides an expedited benefit that accelerates the basic policy's death benefit. This rider covers the insured if they are unable to undertake or carry out any employment, occupation, or profession to earn or acquire any earnings, compensation, or profit prior to the policy anniversary occurring on or immediately after the insured's 65th birthday.</p>
        </li>
        <li>
          <p><strong>Premium Waiver riders.</strong> The Premium Waiver riders allow you to waive future premiums should critical illness or disability happen.</p>
        </li>
      </ol>
      </div>

      <div>
        <h2 className="primary-h2">Other Benefits and Features Available for AIA Secure Flexi Term</h2>
        <p className="mb-8">
          While it might seem like overkill at a first glance, here are some key moments in which purchasing life insurance makes sense:
        </p>
        <ol className="list-decimal list-outside mb-8 pl-4">
          <li>
            <p><strong>Guaranteed Renewable.</strong> The AIA Secure Flexi Term plan offers a renewable term option, where you can choose coverage for 5, 10, 20, or 30 years and renew your policy at the end of each term until age 101, regardless of your health condition.</p>
          </li>
          <li>
            <p><strong>Guaranteed Convertibility.</strong> The AIA Secure Flexi Term plan allows you to convert your basic policy to a whole life, endowment, or investment-linked policy that provides equal or similar cover, without further medical underwriting, up to the current insured amount, before age 70.</p>
          </li>
        </ol>
      </div>

      <div>
        <h2 className="primary-h2">What's Missing</h2>
        <p className="mb-8">
          Here are some features and benefits that other similar term life policies offer that may not be available with AIA Secure Flexi Term:
        </p>
        <ol className="list-decimal list-outside mb-8 pl-4">
          <li>
            <p><strong>Mortgage Insurance.</strong> Decreasing coverage to match the outstanding home loan amount. Ensures the family does not lose the home.</p>
          </li>
          <li>
            <p><strong>Guaranteed Issuance.</strong> AIA Secure Flexi Term does not allow you to increase your coverage without additional medical underwriting.</p>
          </li>
        </ol>
      </div>

      <div className="mt-8 mb-4 bg-gray-100 py-8">
        <MiniExplore criteria="" header="People who've been suggested this policy in their personal proposals" header_class="text-3xl font-bold mb-8" cta_text='See all insurance suggestions →'/>
      </div>

    <h2 className="text-2xl font-bold mt-8 mb-4">Claims Info</h2>
      <p className="mb-8">
        While it might seem like overkill at a first glance, here are some key moments in which purchasing life insurance makes sense:
      </p>
      <ol className="list-decimal list-inside">
        <li>
          <strong>Critical Illness Options</strong> Riders available include TPD Advance Cover Plus II, CI Advance Cover Plus III, MultiPay Critical Illness Cover IV, and Early Critical Illness Cover II.
        </li>
        <li>
          <strong>Premium waiver option.</strong> Premium waiver riders are available, including Critical Illness Premium Waiver II, Payer Critical Illness Premium Waiver II, and Payer Premium Waiver Benefit.
        </li>
      </ol>

      <h2 className="text-2xl font-bold mt-8 mb-4">About Singlife</h2>
      <p className="mb-8">
      First of all you need to understand how Whatins works. This library is not another framework. Rather, it is a set of components based on Tailwind CSS that you can just copy-paste from the documentation.
      </p>
      <p>
      It also includes a JavaScript file that enables interactive components, such as modals, dropdowns, and datepickers which you can optionally include into your project via CDN or NPM.
      </p>
      <p>You can check out the quickstart guide to explore the elements by including the CDN files into your project. But if you want to build a project with Whatins I recommend you to follow the build tools steps so that you can purge and minify the generated CSS.</p>
    </div>
  );
};

const StickyRightBar: React.FC = () => {
  return (
    <div className='hidden md:block md:ml-8 md:top-8 md:sticky '>
    <div className=" border border-gray-300 w-full rounded-2xl mt-8 p-4">
      <ul className="space-y-1">
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            Overview
          </a>
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            Summary
          </a>
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            Key features
          </a>
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            What's being protected
          </a>
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            Optional Riders
          </a>
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            Why it's been suggested
          </a>
        </li>
      </ul>
      <ul className="space-y-1 pt-6">
        <li>
            Others
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            How to make claim
          </a>
        </li>
        <li>
          <a href="/" className="text-blue-500 hover:underline">
            About Singlife
          </a>
        </li>
      </ul>
    </div>
    <div className='flex flexcol md:max-w-screen-2xl px-4 mx-auto'>
        <GetStartedSidebar />
      </div>
    <div className=" bg-gray-100 w-full rounded-2xl mt-8 p-4">
    <div className="space-y-2">
      <div className='text-capitalize'>
          Related Policies
      </div>
      {Array.from({ length: 5 }, (_, index) => (
        <div key={index}>
          <a href="/" className="hover:cursor-pointer">
            <p className="font-semibold">Policy Name</p>
            <p className="text-sm">Description or one-liner to start with...</p>
          </a>
        </div>
      ))}
    </div>
    </div>
  </div>
  );
};

const MobileDropdown: React.FC = () => {
  return (
    <div className="md:hidden">
      <select className="block w-full p-2 border border-gray-300 rounded">
        <option value="">Select a page</option>
        <option value="/">Overview</option>
        <option value="/">Summary</option>
        <option value="/">Key features</option>
        <option value="/">Useful link</option>
        <option value="/">Another link</option>
        <option value="/">Some other link</option>
      </select>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <div className="flex flex-col md:max-w-screen-2xl md:mx-auto px-4">
      <div className='w-full flex flex-col md:flex-row mt-8 gap-8'>
        <div className='flex flex-col md:w-3/4'>
          <p>Term Life Insurance</p>
          <h1 className='primary-h1 py-4'>AIA Secure Flexi Term</h1>
          <p>AIA Secure Flexi Term is a regular premium non-participating term life insurance policy that offers protection at relatively affordable rates and allows you to renew your policy on a regular basis. It offers additional benefits to enhance your coverage such as the Total and Permanent Disability (TPD) Benefit and the Critical Illness (CI) Benefit, making it a comprehensive and flexible term life insurance plan that can cater to your evolving protection needs.
          </p>
        </div>
        <div className='md:w-1/4'>
        <Image src='wheelchair.png' imgClass='max-h-40'/>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
      <div className="md:w-3/4">
        <Overview />
      </div>
      <div className="md:w-1/4">
        <StickyRightBar />
        <MobileDropdown />
      </div>
      </div>
    </div>
  );
};

export default App;