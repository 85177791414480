import React, { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet'



const Terms = () => {

  return (
    <section>
      <Helmet>
        <title>Whatins</title>
        <meta name="description" content="Discover what people like you are buying and their insights, while exploring your personalised insurance options without sharing your phone number. Talk only to the experts you choose."/>
        <meta property="og:title" content="Whatins"/>
        <meta property="og:description" content="Discover what people like you are buying and their insights, while exploring your personalised insurance options without sharing your phone number. Talk only to the experts you choose."/>
        <meta property="og:image" content="/images/whatins.png"/>
        <meta property="og:url" content="https://www.whatins.sg"/>
      </Helmet>
      <section className="bg-white-1">
        <div className='py-8 px-4 mx-auto max-w-screen-xl lg:gap-12 xl:gap-0 lg:py-16 lg:grid-cols-12'>
      
	<p>Welcome to Whatins, a platform owned and operated by Savvy Publishing Holdings Pte Ltd, a company incorporated in Singapore ("Company," "we," "us," or "our"). By accessing or using our website and services, you agree to be bound by these Terms and Conditions ("Terms"), our Privacy Policy, and any additional terms and conditions that may apply to specific sections of the website or to products and services available through the website.</p>
  <h2>1. Eligibility</h2>
<p>1.1 You must be at least 18 years old to use our platform and services.</p>
<p>1.2 By using our platform, you represent and warrant that you have the legal capacity and authority to enter into these Terms.</p>

<h2>2. User Information</h2>
<p>2.1 When you sign up for our services, you agree to provide accurate and complete information, including your email address and financial information.</p>
<p>2.2 You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
<p>2.3 We collect and store your personal information in accordance with our Privacy Policy.</p>

<h2>3. Sharing Information with Advisors</h2>
<p>3.1 By using our platform, you consent to the sharing of your questionnaire responses with the insurance advisors assigned to you.</p>
<p>3.2 The information shared with the advisors will be used solely for the purpose of providing personalized advice and recommendations.</p>

<h2>4. Premiums On Us Campaign</h2>
<p>4.1 The Premiums On Us campaign is a quarterly prize draw held on the 8th of January, April, July, and October.</p>
<p>4.2 To be eligible for the prize, you must purchase an insurance policy through one of the advisors assigned to you on the Whatins platform.</p>
<p>4.3 The prize winner will be notified via email within 5 business days of the draw date. The winner must claim their prize within 10 business days of notification.</p>
<p>4.4 The prize will be disbursed as a bank transfer to the winner's designated account.</p>
<p>4.5 There are no tax implications for the winner of the Premiums On Us prize.</p>
<p>4.6 The Premiums On Us campaign will continue until further notice. We reserve the right to modify or terminate the campaign at any time.</p>

<h2>5. User Conduct</h2>
<p>5.1 You agree to use our platform and services only for lawful purposes and in a manner that does not infringe upon the rights of others or restrict or inhibit their use and enjoyment of the platform.</p>
<p>5.2 You are solely responsible for your interactions with the insurance advisors and any resulting transactions or agreements.</p>

<h2>6. Intellectual Property</h2>
<p>6.1 All content on the Whatins platform, including text, graphics, logos, images, and software, is the property of Savvy Publishing Holdings Pte Ltd or its content suppliers and is protected by Singapore and international copyright laws.</p>

<h2>7. Limitation of Liability</h2>
<p>7.1 In no event shall Savvy Publishing Holdings Pte Ltd be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Whatins platform or services.</p>

<h2>8. Modifications to Terms and Conditions</h2>
<p>8.1 We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page with an updated effective date.</p>
<p>8.2 Users will be notified of any changes to the Terms and Conditions via email at least 2 weeks prior to the effective date.</p>

<h2>9. Governing Law and Dispute Resolution</h2>
<p>9.1 These Terms and Conditions shall be governed by and construed in accordance with the laws of Singapore.</p>
<p>9.2 Any disputes arising out of or in connection with these Terms shall be resolved through amicable negotiations. If the dispute cannot be resolved through negotiations, it shall be referred to and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the Singapore International Arbitration Centre.</p>

<h2>10. Data Protection Notice to Customers</h2>
<p>Data Protection Notice to Customers</p>
<p>This Data Protection Notice ("Notice") sets out the basis which Whatins ("we", "us", or "our") may collect, use, disclose or otherwise process personal data of our customers in accordance with the Personal Data Protection Act ("PDPA"). This Notice applies to personal data in our possession or under our control, including personal data in the possession of organisations which we have engaged to collect, use, disclose or process personal data for our purposes.</p>
  <p>10.1 As used in this Notice:"customer" means an individual who (a) has contacted us through any means to find out more about any goods or services we provide, or (b) may, or has, entered into a contract with us for the supply of any goods or services by us; and"personal data" means data, whether true or not, about a customer who can be identified: (a) from that data; or (b) from that data and other information to which we have or are likely to have access.</p>
  <p>10.2 Depending on the nature of your interaction with us, some examples of personal data which we may collect from you include name, residential address, email address, telephone number, nationality, gender, date of birth, marital status, employment information and financial information.</p>
  <p>10.3 Other terms used in this Notice shall have the meanings given to them in the PDPA (where the context so permits).</p>
  <p>10.4 We generally do not collect your personal data unless (a) it is provided to us voluntarily by you directly or via a third party who has been duly authorised by you to disclose your personal data to us (your "authorised representative") after (i) you (or your authorised representative) have been notified of the purposes for which the data is collected, and (ii) you (or your authorised representative) have provided written consent to the collection and usage of your personal data for those purposes, or (b) collection and use of personal data without consent is permitted or required by the PDPA or other laws. We shall seek your consent before collecting any additional personal data and before using your personal data for a purpose which has not been notified to you (except where permitted or authorised by law).</p>
  <p>10.5 We may collect and use your personal data for any or all of the following purposes:</p>
  <p>10.6 performing obligations in the course of or in connection with our provision of the goods and/or services requested by you;</p>
  <p>10.7 any other purposes for which you have provided the information; and</p>
  <p>10.8 transmitting to any unaffiliated third parties including our third party service providers and agents, and relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned purposes.</p>
  <p>10.9 We may disclose your personal data:to third party service providers, agents and other organisations we have engaged to perform any of the functions with reference to the above mentioned purposes.</p>
  <p>10.10 The consent that you provide for the collection, use and disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop collecting, using and/or disclosing your personal data for any or all of the purposes listed above by submitting your request in writing or via email to our Data Protection Officer at the contact details provided below.</p>
  <p>10.11 Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the complexity of the request and its impact on our relationship with you) for your request to be processed and for us to notify you of the consequences of us acceding to the same, including any legal consequences which may affect your rights and liabilities to us. In general, we shall seek to process your request within seven (7) business days of receiving it.</p>
  <p>10.12 Whilst we respect your decision to withdraw your consent, please note that depending on the nature and scope of your request, we may not be in a position to continue providing our goods or services to you and we shall, in such circumstances, notify you before completing the processing of your request. Should you decide to cancel your withdrawal of consent, please inform us in writing in the manner described in clause 7 above.</p>
  <p>10.13 Please note that withdrawing consent does not affect our right to continue to collect, use and disclose personal data where such collection, use and disclose without consent is permitted or required under applicable laws.</p>
  <p>10.14 If you wish to make (a) an access request for access to a copy of the personal data which we hold about you or information about the ways in which we use or disclose your personal data, or (b) a correction request to correct or update any of your personal data which we hold about you, you may submit your request in writing or via email to our Data Protection Officer at the contact details provided below.</p>
  <p>10.15 Please note that a reasonable fee may be charged for an access request. If so, we will inform you of the fee before processing your request.</p>
  <p>10.16 We will respond to your request as soon as reasonably possible. In general, our response will be within seven (7) business days. Should we not be able to respond to your request within thirty (30) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request. If we are unable to provide you with any personal data or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the PDPA).</p>
  <p>10.17 To safeguard your personal data from unauthorised access, collection, use, disclosure, copying, modification, disposal or similar risks, we have introduced appropriate administrative, physical and technical measures such as use of privacy filters, and disclosing personal data both internally and to our authorised third party service providers and agents only on a need-to-know basis.</p>
  <p>10.18 You should be aware, however, that no method of transmission over the Internet or method of electronic storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your information and are constantly reviewing and enhancing our information security measures.</p>
  <p>10.19 We generally rely on personal data provided by you (or your authorised representative). In order to ensure that your personal data is current, complete and accurate, please update us if there are changes to your personal data by informing our Data Protection Officer in writing or via email at the contact details provided below.</p>
  <p>10.20 We may retain your personal data for as long as it is necessary to fulfil the purpose for which it was collected, or as required or permitted by applicable laws.</p>
  <p>10.21 We will cease to retain your personal data, or remove the means by which the data can be associated with you, as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal data was collected, and is no longer necessary for legal or business purposes.</p>
  <p>10.22 We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we will obtain your consent for the transfer to be made and we will take steps to ensure that your personal data continues to receive a standard of protection that is at least comparable to that provided under the PDPA.</p>
  <p>10.23 You may contact our Data Protection Officer if you have any enquiries or feedback on our personal data protection policies and procedures, or if you wish to make any request, in the following manner:</p>
  <p>Name of DPO : Mr CS<br/>Contact No. : +6596286418<br/>Email Address : admin at whatins dot sg</p>
  <p>10.24 This Notice applies in conjunction with any other notices, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of your personal data by us.</p>
  <p>10.25 We may revise this Notice from time to time without any prior notice. You may determine if any such revision has taken place by referring to the date on which this Notice was last updated. Your continued use of our services constitutes your acknowledgement and acceptance of such changes.</p>
  <p>Effective date : 10/09/2023</p>
  <p>Last updated : 10/09/2023</p>
  <h2>11. Contact Us</h2>
<p>11.1 If you have any questions, concerns, or complaints regarding these Terms or our services, please contact us at admin at whatins dot sg.</p>

<p>By using the Whatins platform and services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
</div>
</section>
    </section>
  );
};


export default Terms;
