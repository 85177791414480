import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation } from 'react-router-dom';
import { GlobalProvider } from './components/GlobalState';
import Home from './pages/home';
import Main from './pages/main';
import {AdvertisingLP} from './pages/lp-get-proposals';
import Questionnaire2 from './pages/questionnaire';
import Nav from './components/nav';
import Footer from './components/footer';
import AdvisorNav from './components/AdvisorNav';
import {AdvisorLogin} from './pages/advisor-external';
import {Blog} from './pages/insurance-products';
import InsurancePolicyPage from './pages/insurance-policies';
import Diary from './pages/diary';
import Contact from './pages/contact';
import Register from './pages/advisor-lp';
import Admin from './pages/admin';
import ProspectCentre from './pages/prospect-centre';
import AdvisorDashboard, {AdvisorResourcesPage} from './pages/advisor-dashboard';
import InsuranceStories from './pages/insurance-stories';
import AdvisorProspect from './pages/advisor-prospect-list';
import Terms from './pages/legal';
import { initializeTagManager } from './components/GTM';
import formbricks from "@formbricks/js";
import posthog from 'posthog-js'
import { Toaster } from "./components/ui/toaster"
// import HubspotChat  from "./components/HubspotChat"

posthog.init('phc_H7r5n1l5CkPKZmZiIl5PAuRDS5uud8m50OEQwy9u4UX', { api_host: 'https://app.posthog.com' })

const MemberLayout = () => {
  const location = useLocation();
  const isAdvisorProspectRoute = location.pathname === '/advisor/prospects';

  return (
    <div className="flex flex-col h-screen">
      <AdvisorNav />
      <main className={`flex-grow ${isAdvisorProspectRoute ? 'overflow-hidden' : ''}`}>
        <Outlet />
      </main>
    </div>
  );
};

// ... rest of the file remains unchanged

interface GeneralLayoutProps {
  children?: React.ReactNode;
}

const GeneralLayout: React.FC<GeneralLayoutProps> = ({ children }) => {
  return (
    <>
      <Nav />
      {children || <Outlet />}
      <Footer/>
      {/* <HubspotChat/> */}
    </>
  );
};

if (typeof window !== "undefined") {
  formbricks.init({
    environmentId: "clssatcpc2nzdufi54ni385tp",
    apiHost: "https://app.formbricks.com",
    debug: true,
  });
}

const LPRoute: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmSource = searchParams.get('utm_source');

  return (
    <div>
      {utmSource ? (
        <AdvertisingLP />
      ) : (
        <GeneralLayout>
        <AdvertisingLP />
      </GeneralLayout>
      )}
    </div>
  );
};

function App() {
  useEffect(() => {
    initializeTagManager();
  }, []); // Empty dependency array means this runs once on component mount

  return (
    <Router>
      <Routes>
        <Route path='/' element={<GeneralLayout/>}>
          <Route index element={<Home />} />
          <Route path="explore" element={<Main />} />
          {/* <Route path="insurance-diary/*" element={<Diary />} /> */}
          <Route path="insurance-story/:slug" element={<InsuranceStories />} />
          <Route path="insurance-products/*" element={<Blog />} />
          <Route path="insurance-products/*-review" element={<Blog />} />
          <Route path="insurance-policies" element={<InsurancePolicyPage />} />
          <Route path="insurance-stories" element={<InsuranceStories />} />
          <Route path="advisor-login" element={<AdvisorLogin />} />
          <Route path="advisor-register" element={<Register />} />
          <Route path="contact" element={<Contact />} />
          <Route path="terms" element={<Terms />} />
        </Route>
        <Route path='/lp'>
          <Route path="get-proposals" element={<LPRoute />} />
        </Route>
        <Route path="/questionnaire/start" element={<Questionnaire2 />} />
        <Route path="/prospect/*" element={<ProspectCentre />} />
        <Route path="/advisor" element={<GlobalProvider> <MemberLayout/> </GlobalProvider>}>
          <Route path="profile" element={<AdvisorDashboard />} />
          <Route path="prospects" element={<AdvisorProspect />} />
          <Route path="onboarding" element={<AdvisorResourcesPage />} />
          <Route path="admin" element={<Admin />} />
        </Route>
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;