import React from 'react';

export const IconStar = ({ className = 'h-6 w-6', color = 'fill-blue-500' }) => (
  <svg
    className={`${className} ${color}`}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757
      c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042
      c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685
      c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528
      c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956
      C22.602,0.567,25.338,0.567,26.285,2.486z"/>
  </svg>
);

export const Archive = ({ className = 'h-6 w-6', color = 'fill-blue-500' }) => (
  <svg
  className={`${className} ${color}`}
  viewBox="0 0 400 400"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="xMidYMid meet"
>
<path id="XMLID_589_" d="M197.074,216.42c0,6.075-4.925,11-11,11h-40.612c-6.075,0-11-4.925-11-11s4.925-11,11-11h40.612
	C192.149,205.42,197.074,210.345,197.074,216.42z M331.533,51.903v68.317c0,6.075-4.925,11-11,11h-7.13V279.63
	c0,6.075-4.925,11-11,11H29.131c-6.075,0-11-4.925-11-11V131.221H11c-6.075,0-11-4.925-11-11V51.903c0-6.075,4.925-11,11-11h309.533
	C326.608,40.903,331.533,45.828,331.533,51.903z M291.403,131.221H221.24c-5.133,25.933-28.055,45.545-55.472,45.545
	c-27.417,0-50.34-19.612-55.472-45.545H40.131V268.63h251.272V131.221z M165.768,154.766c15.205,0,28.147-9.873,32.75-23.545
	h-65.501C137.621,144.893,150.563,154.766,165.768,154.766z M309.533,62.903H22v46.317h287.533V62.903z"/>
</svg>
);

// Add more SVG icon components as needed

export const SvgIcons = {
  IconStar,
  Archive,

  // Export other icon components
};