import Image  from "./image"

const Numbers = () => {
  return (
<div className='flex flex-col md:flex-row w-full justify-between max-w-2xl md:mx-auto md:pt-4 gap-2 md:gap-6 px-4 md:px-0'>
  <div className="justify-between md:justify-start items-center gap-2.5 flex">
            <p className=" primary-h2">1000+</p>
            <div className="Text flex-col justify-start items-start inline-flex">
              <p className="OurActiv text-gray-500 text-sm font-normal">Suggestions facilitated</p>
            </div>
          </div>
          <div className="Fact2 justify-between md:justify-start  items-center gap-2.5 flex">
          <p className=" primary-h2">50+</p>
            <div className="Text flex-col justify-start items-start inline-flex">
              <p className="OurActiv text-gray-500 text-sm font-normal ">Insurance Experts</p>
            </div>
          </div>
          <div className="Fact3 justify-between md:justify-start items-center gap-2.5 flex">
          <p className=" primary-h2">10+</p>
            <div className="Text flex-col justify-start items-start inline-flex">
              <p className="OurActiv text-gray-500 text-sm font-normal ">Insurance Companies</p>
            </div>
          </div>
  </div>
  )}

  const Logos = ({ text = 'Our Experts are from' }) => {
    return (
      <section className="bg-white-1">
    <div className="py-8 mx-auto max-w-screen-xl px-4">
        <h2 className="mb-8 lg:mb-16 text-h5 font-extrabold tracking-tight leading-tight text-center text-green-1 uppercase">{text}</h2>
        <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
        <Image src='prudential-logo.png' imgClass='rounded-none w-24'/>
        <Image src='great-eastern-logo.png' imgClass='rounded-none w-24'/>
        <Image src='income-logo.png' imgClass='rounded-none w-24'/>
        <Image src='synergy-finanical-logo.png' imgClass='rounded-none w-24'/>
        <Image src='finexis-logo.png' imgClass='rounded-none w-24'/>
        <Image src='aia-logo.png' imgClass='rounded-none w-24'/>
        </div>
    </div>
</section>
    );
  };

export { Numbers };
export { Logos };