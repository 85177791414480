import React from 'react';

const getMatchedType = (inputType) => {
  const typeList = [
    { input: ['isp', 'hospital', 'Medical Condition', 'recpdtMoJo1cA8FH8'], output: 'Hospital', color: '#FF4136', typeId: 'recpdtMoJo1cA8FH8' },
    { input: ['pa', 'personal accident', 'recmDVlhrGH7Pa0b9', 'personal'], output: 'Personal Accident', color: '#FF851B', typeId: 'recmDVlhrGH7Pa0b9' },
    { input: ['disability', 'tpd', 'total permanent disability'], output: 'Disability', color: '#FFDC00', typeId: 'recjs035wuJLCIfoX' },
    { input: ['whole', 'whole life', 'recY8Oy3y6KEMQytx'], output: 'Whole Life', color: '#2ECC40', typeId: 'recY8Oy3y6KEMQytx' },
    { input: ['term', 'term life', 'recpnPXgTA9KwVmcI'], output: 'Term Life', color: '#0074D9', typeId: 'recpnPXgTA9KwVmcI' },
    { input: ['ilp', 'investment', 'linked', 'plan', 'recldvICdozX8cFCp', 'investment linked plan'], output: 'Investment Linked Plan', color: '#B10DC9', typeId: 'recldvICdozX8cFCp' },
    { input: ['endowment', 'saving', 'rec6MQaW9daZ619Bs'], output: 'Endowment', color: '#F012BE', typeId: 'rec6MQaW9daZ619Bs' },
    { input: ['ci', 'critical illness', 'recfuZEbbuVfjNTWQ', 'critical'], output: 'Critical Illness', color: '#001f3f', typeId: 'recfuZEbbuVfjNTWQ' },
  ];

  if (typeof inputType !== 'string') {
    return null;
  }

  return typeList.find(
    (item) => item.input.some((word) => word.toLowerCase() === inputType.toLowerCase())
  );
};

const TypeTag = ({ type, className = 'text-gray-500 text-sm' }) => {
  const matchedType = getMatchedType(type);

  if (!matchedType) {
    return null;
  }

  const { output, color, typeId } = matchedType;

  return (
    <span className={`inline-flex items-center rounded-full px-2 py-0.5 ${className}`} data-type-id={typeId}>
      <span className="inline-block w-2 h-2 rounded-full mr-1" style={{ backgroundColor: color }}></span>
      {output}
    </span>
  );
};

export default TypeTag;
export {getMatchedType}