import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC7noeHe9JEMOlaUQ6mo6VKNRDtqRX3L44",
  authDomain: "auth.whatins.sg",
  databaseURL: "https://whatsins-backend-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "whatsins-backend",
  storageBucket: "whatsins-backend.appspot.com",
  messagingSenderId: "760479142270",
  appId: "1:760479142270:web:2ed9252c98a62207fca32f",
  measurementId: "G-S7V6VD78ZT"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export default auth;