import React, { useEffect, useState, useMemo } from 'react';
import Explore from '../components/explore'
import Image from '../components/image'
import Helmet from 'react-helmet'
import { GetStarted } from '../components/CTA'

const Main = () => {
  return (
    <section className='bg-white-1'>
      <Helmet>
        <title>Whatins</title>
        <meta name="description" content="Insurance made transparent & easy"/>
        <meta property="og:title" content="Whatins"/>
        <meta property="og:description" content="Insurance made transparent & easy"/>
        <meta property="og:image" content="/images/whatins.png"/>
        <meta property="og:url" content="https://www.whatins.sg"/>
      </Helmet>
      <Explore path='api/new-start'/>
      <div className='flex flex-col max-w-screen-2xl mx-auto'><GetStarted/></div>
    </section>
  );
};


export default Main;
