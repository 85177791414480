interface ErrorReport {
  message: string;
  url: string;
  function: string;
  componentName: string;
  additionalInfo?: Record<string, any>;
  stackTrace?: string;
  timestamp?: string; 
  userAgent?: string; 
  screenSize?: string;
}

let errorQueue: ErrorReport[] = [];
let isReporting = false;

export const reportError = async (errorData: ErrorReport) => {
  errorQueue.push({
    ...errorData,
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    screenSize: `${window.innerWidth}x${window.innerHeight}`,
  });

  if (!isReporting) {
    isReporting = true;
    await sendErrors();
    isReporting = false;
  }
};

const sendErrors = async () => {
  while (errorQueue.length > 0) {
    const batchSize = Math.min(errorQueue.length, 10);
    const batch = errorQueue.splice(0, batchSize);

    try {
      const response = await fetch('/api/report-error', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(batch),
      });

      if (!response.ok) {
        throw new Error('Failed to send error batch');
      }
    } catch (error) {
      console.error('Failed to send error batch:', error);
      // Add the failed batch back to the queue
      errorQueue.unshift(...batch);
      break;
    }
  }
};