import React, { useEffect, useState, useMemo } from 'react';
import Helmet from 'react-helmet'
import { useForm } from "react-hook-form"
import { useToast } from "../components/ui/use-toast"




const Contact = () => {
  const { register, handleSubmit } = useForm();
  const { toast } = useToast()

  async function onSubmit(values) {
    const lowercaseEmail = values.email ? values.email.toLowerCase() : '';
  
    try {
      const response = await fetch('/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...values, email: lowercaseEmail }),
      });
  
      if (!response.ok) {
        toast({
          title: 'Whoops...',
          description: 'It seems like there was an error recieving the message. Please try again or email admin [at] whatins.sg'
        })
      } else {
        toast({
          title: 'We got your message!',
          description: "We'll try to get back to you in 3 business days."
        })
      }
    } catch (error) {
      toast({
        title: 'Whoops...',
        description: 'It seems like we already have a request from you. If this is a mistake, please email us at admin@sav.finance'
      })
    }
  }




  return (
    <section>
      <Helmet>
        <title>Contact Whatins</title>
        <meta name="description" content="Contact Whatins for any questions you may have."/>
        <meta property="og:title" content="Contact Whatins"/>
        <meta property="og:description" content="Contact Whatins for any questions you may have."/>
        <meta property="og:image" content="/images/whatins.png"/>
        <meta property="og:url" content="https://www.whatins.sg"/>
      </Helmet>
      <section className="bg-gray-50 dark:bg-gray-900">
    <div className="flex flex-col mx-auto max-w-screen-lg gap-6 w-full px-4 py-4 md:py-24">
      <h1 className='primary-h1 text-center'>Contact us</h1>
      <p className='text-center'>We will try to answer your enquiries within 3 business days.</p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8 max-w-md mx-auto w-full">
        <div className='w-full col-span-2'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
            <input type="email" id="email" {...register('email')} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@gmail.com" required/>
        </div>
        <div className='w-full col-span-2'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
            <input type="text" id="subject" {...register('subject')} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required/>
        </div>
        <div className='w-full col-span-2'>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message?</label>
            <textarea id="message" {...register('message')} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder=""></textarea>
      </div>
      <button type="submit" className="primary-button2 w-full">Submit</button>
        
      </form>
      </div>
</section>
    </section>
  );
};


export default Contact;
