import React, { useState, useEffect } from 'react';
import Image from './image'

interface ImageDisplayProps {
    urls: string[];
    maxHeight?: string;
    orientation?: string;
    layout?: string;
}

const ImageGrid: React.FC<ImageDisplayProps> = ({ layout = 'default', urls, maxHeight = 'auto' }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (urls && urls.length > 0) {
            setCurrentImageIndex(0); // Initialize with the first image index
        }
    }, [urls]);

    const updateMainImage = (url: string, index: number) => {
        setCurrentImageIndex(index); // Update to use index for navigation
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const navigateImage = (direction: 'next' | 'prev') => {
        let newIndex = direction === 'next' ? currentImageIndex + 1 : currentImageIndex - 1;
        // Loop back to the start or end if out of bounds
        if (newIndex < 0) {
            newIndex = urls.length - 1;
        } else if (newIndex >= urls.length) {
            newIndex = 0;
        }
        setCurrentImageIndex(newIndex);
    };

    if (!urls || urls.length === 0) {
        return <div>No images to display</div>;
    }

    const currentImageUrl = `/images/${urls[currentImageIndex]}`;

    // Determine layout classes based on the layout prop
    const containerClasses = layout === 'default' 
        ? 'flex flex-col gap-4'
        : 'flex flex-row gap-4';
    
    const mainImageClasses = layout === 'default'
        ? 'w-full'
        : 'w-3/4 h-full'; // Ensure full height for side layout

    const thumbnailsClasses = layout === 'default'
        ? 'grid grid-cols-5 gap-4'
        : 'flex flex-col gap-4 w-1/4 overflow-y-auto'; // Scrollable column for side layout

    return (
        <div className={`${containerClasses} h-full`} style={{ maxHeight: maxHeight }}>
            <div className={`${mainImageClasses}`}>
                <img
                    id="main-img"
                    className="w-full h-full object-contain rounded-xl cursor-pointer"
                    src={currentImageUrl}
                    alt="Main Display"
                    loading="lazy"
                    onClick={toggleModal}
                    style={layout === 'side' ? { maxHeight: '100%' } : {}}
                />
            </div>
            <div className={thumbnailsClasses} style={layout === 'side' ? { maxHeight: '100%' } : {}}>
                {urls.map((url, index) => (
                    <div key={index} className={layout === 'side' ? 'h-1/5' : ''}>
                        <img
                            src={`/images/${url}`}
                            className={`thumbnail w-full h-full object-cover rounded-lg ${currentImageIndex === index ? 'border-2 border-black' : ''}`}
                            alt="Thumbnail"
                            loading="lazy"
                            onClick={() => updateMainImage(url, index)}
                        />
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <div
                className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-600 bg-opacity-50"
                onClick={toggleModal}
            >
                <div className="relative max-w-full max-h-full w-auto h-auto">
                    <img
                        src={currentImageUrl}
                        className="max-w-full max-h-full w-auto h-auto object-contain"
                        alt="Expanded"
                        style={{
                            maxHeight: 'calc(100vh)', // Adjusts based on the viewport height, 1rem is subtracted for some spacing
                            maxWidth: 'calc(100vw)' // Adjusts based on the viewport width, 1rem is subtracted for some spacing
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevents modal close when clicking on the image itself
                    />
                    <button
                        className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white bg-black bg-opacity-0 p-4 "
                        onClick={(e) => { e.stopPropagation(); navigateImage('prev'); }}
                        style={{ zIndex: 10 }}
                    >
                        <Image src='left-arrow.svg' className='w-4 md:w-16'/>
                    </button>
                    <button
                        className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white bg-black bg-opacity-0 p-4"
                        onClick={(e) => { e.stopPropagation(); navigateImage('next'); }}
                        style={{ zIndex: 10 }}
                    >
                      <Image src='right-arrow.svg' className='w-4 md:w-16'/>
                    </button>
                </div>
            </div>
            
            )}
        </div>
    );
};

export default ImageGrid;
