import React from 'react';

interface GIFProps {
  src: string; // Renamed from 'path' to 'src'
  alt?: string;
  className?: string; // Optional className prop
  imgClass?: string; // Optional className prop
}

const GIF: React.FC<GIFProps> =  ({ src = "loading.mp4", className}) => {
  const videoPath = `/images/${src}`; 
  const defaultClasses = "absolute inset-0 z-50 flex justify-center items-center";
  return (
    <div className={className ? className : defaultClasses}>
      <video className="h-24" autoPlay loop muted>
        <source src={videoPath} type="video/mp4" />
      </video>
    </div>
  );
};

export default GIF;
