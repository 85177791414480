import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Image from '../components/image'
import { useNavigate } from 'react-router-dom';
import TypeTag from '../components/TypeTag';
import Helmet from 'react-helmet'
import { Logos } from '../components/SocialProof'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion"
import { GetStarted, GetStartedSample } from '../components/CTA'
import { MiniExplore } from '../components/explore';

const App: React.FC = () => {
  const [blog, setBlog] = useState<any>(null);
  const [recordid, setRecordid] = useState<string>('');
  const [submissions, setSubmissions] = useState<any[]>([]);
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();

  function formatCurrency(value) {
    // Check if the value is null or undefined; return a default string in such cases
    if (value === null || value === undefined) {
      return "$0";
    }
    // Convert the value to a number in case it's a string, then format it
    return `$${parseInt(value).toLocaleString()}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/insurance-story/${slug}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setRecordid(data.recordid);
        setBlog(data.blog);
        setSubmissions(data.submissions);

      } catch (error) {
        navigate('/explore');
      }
    };
    fetchData();
  }, [slug]); // Dependencies for the useEffect

  interface Policy {
    company: string | null;
    coverage: string | Record<string, number>;
    duration: string | null;
    name: string | null;
    payment_term: string | null;
    policy_id: string | null;
    premium: number | null;
    rationale: string | null;
    slug: string | null;
    type: string | null;
    type_id: string | null;
  }
  
  interface PersonalizedProposalProps {
    submission: {
      advisor_name: string;
      total_premium: string;
      products: Policy[];
    };
  }
  
  const PersonalizedProposal: React.FC<PersonalizedProposalProps> = ({ submission }) => {
    const { advisor_name, total_premium, products } = submission;

    const renderCoverage = (coverage: string | Record<string, any>) => {
      if (!coverage) {
        return null;
      }
    
      if (typeof coverage === 'string') {
        return coverage;
      } else {
        return (
          <div>
            {Object.entries(coverage).map(([key, value]) => {
              if (typeof value === 'object' && value !== null) {
                return (
                  <div key={key} className="w-full flex flex-col gap-2">
                    <div className="font-semibold text-gray-500 text-base capitalize">{key.replace(/_/g, ' ')}:</div>
                    {renderCoverage(value)}
                  </div>
                );
              } else {
                return (
                  <div key={key} className="w-full flex justify-between gap-4">
                    <div className="font-semibold text-gray-500 text-base">{key.replace(/_/g, ' ')}:</div>
                    <div className="text-gray-500 text-base">
                      {typeof value === 'number' ? formatCurrency(value) : value}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      }
    };

    return (
    <div className="rounded-lg border p-4 bg-white">
      <h3 className="text-lg text-gray-500">
        Prepared by: <span className="primary-h5 text-green-1 font-bold">{advisor_name}</span>
      </h3>
      <p>Total Premium: {total_premium ? (`$${Number(total_premium).toFixed(2)}`) : 'not provided'}</p>
      <div className="mt-4 space-y-2">
        {products.map((policy, index) => (
          <Accordion type="multiple">
            <AccordionItem value="item-1">
              <AccordionTrigger><div key={index} className='w-full flex justify-between'>
                <div className='flex items-center gap-2 flex-shrink'><Image src="check-circle.svg" className='w-4 h-4 flex-none hidden md:block'/><span className='text-green-1 capitalize text-base text-start '>{policy.name}</span></div>
                <div className='text-end grow shrink-0 items-center'><TypeTag type={policy.type_id}/></div>
              </div></AccordionTrigger>
              <AccordionContent className='space-y-2'>
              {policy.premium && (
                <div className="w-full flex justify-between gap-4">
                  <div className="font-semibold text-gray-500 text-base">Premium: </div>
                  <div className="text-gray-500 text-base">
                    {policy.premium}
                    {policy.payment_term && ` (${policy.payment_term})`}
                  </div>
                </div>
              )}
              {policy.coverage && (
                <div className="w-full flex flex-col gap-2">
                  <div className="font-semibold text-gray-500 text-base">Coverage:</div>
                  <div className="text-gray-500 text-base">{renderCoverage(policy.coverage)}</div>
                </div>
              )}
                <div className='flex flex-col gap-4'>
                  <p className='text-gray-500'>{policy.rationale}</p>
                  {policy.slug && (
                    <a href={`/insurance-products/${policy.slug}`} className='primary-button2 mx-auto'>Learn about {policy.name}</a>
                  )}
                  </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </div>
    );
  };

  return (
    <div>
      {blog && (
        <div>
      <Helmet>
      <title>What insurance was {blog.name} suggested?</title>
      <meta name="description" content={`${blog.name} is a ${blog.age}y/o ${blog.gender} who makes ${blog.monthly_income} as a ${blog.occupation}. See what insurance ${blog.name} was suggested.`}/>
      <meta property="og:title" content={`What insurance was ${blog.name} suggested? | Whatins`}/>
      <meta property="og:description" content={`${blog.name} is a ${blog.age}y/o ${blog.gender} who makes ${blog.monthly_income} as a ${blog.occupation}. See what insurance ${blog.name} was suggested.`}/>
      <meta property="og:image" content="/images/whatins.png"/>
      <meta property="og:url" content={`https://www.whatins.sg/insurance-stories/${slug}`}/>
      
      <meta property="og:type" content="article"/>
      <meta name="twitter:title" content={`What insurance was ${blog.name} suggested? | Whatins`}/>
      <meta name="twitter:description" content={`${blog.name} is a ${blog.age}y/o ${blog.gender} who makes ${blog.monthly_income} as a ${blog.occupation}. See what insurance ${blog.name} was suggested.`}/>
      <meta name="twitter:image" content="/images/whatins.png"/>
      <link rel="canonical" href={`https://www.whatins.sg/insurance-stories/${slug}`}/>
      <meta name="robots" content="index, follow"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="keywords" content={`insurance, ${blog.name}, ${blog.age}, ${blog.gender}, ${blog.occupation}, Singapore`}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Article",
          "headline": `What insurance was ${blog.name} suggested?`,
          "description": `${blog.name} is a ${blog.age}y/o ${blog.gender} who makes ${blog.monthly_income} as a ${blog.occupation}. See what insurance ${blog.name} was suggested.`,
          "image": "/images/whatins.png",
          "author": {
            "@type": "Organization",
            "name": "Whatins"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Whatins",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.whatins.sg/logo.png"
            }
          }
        })}
      </script>
    </Helmet>
    
    <div className='bg-white-1'>
      <section>
        <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg:gap-12 xl:gap-0 lg:py-16 grid-cols-1 lg:grid-cols-12">
            <div className="place-self-center mr-auto mb-10 col-span-1 lg:col-span-7 xl:col-span-7 xl:mb-0">
            <div className='inline-flex gap-4 rounded-lg items-center px-4 bg-white w-fit mb-6'>
          <Image src="check-circle-green.svg" className='w-4 h-4 flex-none'/>
          <p className='text-green-1'>Based on real submissions and proposals</p>
          </div>
                <h1 className="primary-h1 mb-6">Hi, my name is {blog.name}</h1>
                <Image src={`generic/${blog.profile_image}.svg`} imgClass='w-full' className='block md:hidden'/>
                <div className="grid grid-cols-1 md:grid-cols-4 md:divide-x-2 p-4 gap-2 border border-gray-300 rounded-xl shadow-lg my-6">
              <div className='px-4 *:text-center'>
                <div className="primary-h3 text-green-1">{blog.gender}</div>
                <p className="text-gray-500 text-sm">Gender</p>
              </div>
              <div className='px-4 *:text-center'>
              <div className="primary-h3 text-green-1">{blog.age}</div>
                <p className="text-gray-500 text-sm">Age</p>
              </div>

              <div className='px-4 *:text-center'>
              <div className="primary-h3 text-green-1">{blog.smoker}</div>
                <p className="text-gray-500 text-sm">Smoker?</p>
              </div>

              <div className='px-4 *:text-center'>
              <div className="primary-h3 text-green-1">{formatCurrency(blog.monthly_income)}</div>
                <p className="text-gray-500 text-sm">Monthly income</p>
              </div>
              </div>
              <div className='flex flex-col md:flex-row gap-4 my-2 mb-8'>
            <p className='text-h5 text-green-1 font-bold'>Interested In</p>
            {blog.interested_in?.map((type, index) => (
              <TypeTag key={index} type={type} />
            ))}
            </div>
          <div className="flex flex-col md:flex-row gap-8">
          <div className='md:border-r border-gray-300 dark:border-gray-700 pr-8'>
          <Image src="info.svg" className='w-8 h-8 flex-none'/>
          <h2 className='text-h5 text-green-1 font-bold'>Other information</h2>
            <ul className="mt-2 text-gray-500">
              <p>{blog.other_info}</p>
            </ul>
          </div>
          <div className='basis-1/3 shrink-0'>
            <Image src="shield-check.svg" className='w-8 h-8 flex-none'/>
            <h2 className='text-h5 text-green-1 font-bold'>Current insurance plans</h2>
            <ul className="mt-2 text-gray-500">
              {blog.current_insurance}
            </ul>
          </div>
          </div>
          </div>

            <div className="justify-center p-4 max-w-screen-s lg:mt-0 lg:col-span-5 xl:col-span-5 sm:p-6 lg:p-8 dark:bg-gray-800 dark:border-gray-700 hidden md:block">
            <Image src={`generic/${blog.profile_image}.svg`} imgClass='w-full' className='p-16'/>
            </div>                
        </div>
    </section>
      


      <div className='bg-green-1 py-8 md:py-24 md:px-6'>
      <h2 className='primary-h2 text-green-2 text-center'>Personalized proposals for {blog.name}</h2>
        <div className="py-8 grid grid-cols-1 md:grid-cols-3 gap-8 md:max-w-screen-xl mx-auto">
        {submissions?.slice(0, 3).map((submission, index) => (
          <PersonalizedProposal key={index} submission={submission} />
        ))}
        </div>
      </div>
      <div className=''>
        <Logos/>
      </div>

      <div id='Explore'>
      { blog && (
        <MiniExplore
          age={blog.age}
          income={blog.monthly_income}
          exclude={recordid}
          header='Here are some similar stories you might like'
          cta_text='See all insurance suggestions →'
          fallback={<GetStartedSample  />}
        />
      )}
      </div>

      <div className='flex flex-col md:max-w-screen-2xl px-4 mx-auto bg-green-2'>
        <GetStarted />
      </div>
    </div>
    </div>
      )}
    </div>
    
  );
};

export default App;