import React, { useEffect, useState, useRef } from 'react';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from 'react-router-dom';
import { Button } from "./ui/button"
import { useToast } from "../components/ui/use-toast"
import Image  from "../components/image"
import ImageGrid from '../components/imageGrid'
import posthog from 'posthog-js'

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form"
import { Input } from "./ui/input"

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const adVariations = {
  'original': {
    header: "Find the proper policy for you (100% Free)",
    subhead: "Get personalized suggestions from 5 insurance experts, no phone number needed. Plus a chance to get your premiums paid by us!"
  },
  'high-intent': {
    header: "Why get 1 perspective when you can get 5",
    subhead: "Get <b>5 different insurance experts</b> opinions in less time than it takes to hear from 1. Completely online allowing you to <b>stay anonmyous</b> takes only 5 minutes to get started.",
    cta: "Get started →"
  },
  'variation': {
    header: "Get 5 Expert Quotes and Advice in Just 5 Minutes",
    subhead: "Compare personalized insurance quotes and receive expert advice from 5 different advisors. No phone calls, no commitment - just <b>clear, actionable information</b> to help you make the best choice.",
    cta: "Get started →"
  },
  'term': {
    header: "Find The Proper Term Life Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'whole': {
    header: "Find The Proper Whole Life Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'ci': {
    header: "Find The Proper Critical Illness Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'endowment': {
    header: "Find The Proper Endowment Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'hospital': {
    header: "Find The Proper Hospital (ISP) Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'ilp': {
    header: "Find The Proper Investment Linked Plan For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'pa': {
    header: "Find The Proper Personal Accident Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'tpd': {
    header: "Find The Proper Total Permanent Disability Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  }
}

export function useAdVariation() {
  const [adVariation, setAdVariation] = useState<string>('');

  useEffect(() => {
    let variation = localStorage.getItem('adVariation');
    if (!variation) {
      const variations = ['high-intent', 'original'];
      variation = variations[Math.floor(Math.random() * variations.length)];
      localStorage.setItem('adVariation', variation);
    }
    setAdVariation(variation);
  }, []);

  return adVariation;
}

export const headerVariations = {
  1: {
    header: "Personalized Insurance Guidance. Premium-Free Coverage.",
    subhead: "Get matched with 5 expert advisors and a chance to win your monthly premiums paid.",
  },
  2: {
    header: "Unlock Personalized Insurance Advice. Win Premiums On Us.",
    subhead: "Discover the right coverage with 5 expert recommendations - and pay $0 in premiums.",
  },
  3: {
    header: "Effortless Insurance Advice. Premium-Free Protection.",
    subhead: "Connect with 5 advisors, find your perfect policy, and win your monthly premiums.",
  },
  4: {
    header: "Tailored Insurance Solutions. Premium-Free for a Month.",
    subhead: "Get 5 personalized recommendations and a shot at having your premiums covered.",
  },
  5: {
    header: "Expert Insurance Guidance. Premium-Free for 30 Days.",
    subhead: "Explore 5 advisor matches and enter to win a month of free insurance coverage.",
  },
  6: {
    header: "Personalized Insurance Advice. Premiums On Us.",
    subhead: "Discover your ideal policy with 5 expert recommendations and a chance to win.",
  },
  7: {
    header: "Effortless Insurance Matching. Premium-Free Payout.",
    subhead: "Connect with 5 advisors, find your fit, and enter to have your premiums paid.",
  },
  8: {
    header: "Personalized Insurance Recommendations. Premium-Free Prize.",
    subhead: "Get 5 expert suggestions and a shot at having your monthly costs covered.",
  },
  9: {
    header: "Insurance Guidance Made Easy. Premiums On Us Giveaway.",
    subhead: "Explore 5 advisor matches and enter to win a month of premium-free coverage.",
  },
  10: {
    header: "Personalized Insurance Advice. Premium-Free for 30 Days.",
    subhead: "Get 5 expert recommendations and a chance to have your monthly costs covered.",
  },
  11: {
    header: "The Better Way of Finding The Proper Policy For You",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  }
};

const formSchema = z.object({
  alias: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  })
})

function GetStartedFormContent({ sidebarLayout=true, source='cta', randomVariation='', cta='Get your free personalised plans →' }) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [posthogId, setPosthogId] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      alias: "",
      email: "",
    },
  });

  useEffect(() => {
    const id = posthog.get_distinct_id();
    setPosthogId(id);
  }, []);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const lowercaseEmail = values.email.toLowerCase();
    const currentUrl = window.location.href;
    try {
      const response = await fetch('/api/get-proposals', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          ...values, 
          email: lowercaseEmail, 
          url: currentUrl,
          posthogId: posthogId 
        }),
      });

      if (!response.ok) {
        toast({
          title: `Whoops...`,
          description: "We didn't get your request. Please submit again."
        });
      } else {
        toast({
          title: `✅ Signed up successfully`,
          description: "Now we just need a few details about you. Will take less than 5 minuets!"
        });
        const responseData = await response.json();
        posthog.capture('prospect_signed_up', {
          name: values.alias,
          form: 'cta',
          url: currentUrl,
          posthogId: posthogId,
          $set_once: { header_version: randomVariation },
        })
        // Push event to dataLayer for GTM
      if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
          'event': 'sign_up',
          'formType': 'lp',
          'userName': values.alias,
          'userEmail': lowercaseEmail
        });
      }

        navigate('/questionnaire/start', { state: { data: responseData } });
      }

      
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  return (
        <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={`grid grid-cols-1 ${sidebarLayout ? '' : 'md:grid-cols-3'} gap-4`}
>
          <FormField
            control={form.control}
            name="alias"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                  </svg>
                  </div>
                  <Input placeholder="Enter your name" {...field} className="pl-10 bg-white-2"/>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                      <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                      <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                    </svg>
                  </div>
                  <Input placeholder="Enter your email" {...field} className="pl-10 bg-white-2"/>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button variant='primary_2' type="submit" className=" w-full">
            {cta}
          </Button>
          <div className={`${sidebarLayout ? '' : 'md:col-span-3'}`}><p className="text-gray-500 text-sm">100% online. No number required.</p>
          {source ? <a href={`/lp/get-proposals?source=${source}`} className="primary-link test-sm">Learn more</a> : null}
          </div>
          
        </form>
      </Form>
  );
}

export function GetStartedSample({source='cta'}) {
  return (
    <div className='flex flex-col'>
        <div className='flex flex-col md:flex-row gap-14 max-w-screen-xl mx-auto px-4 py-8'>
          <div className='object-scale-down h-1/2 flex basis-1/2 order-last md:order-first'>
            <ImageGrid urls={['sample-proposal/sample-proposal-1-4x5.jpg','sample-proposal/sample-proposal-2-4x5.jpg','sample-proposal/sample-proposal-3-4x5.jpg','sample-proposal/sample-proposal-4-4x5.jpg','sample-proposal/sample-proposal-5-4x5.jpg']}
            maxHeight="h-1/2"/>
          </div>
          <div className='order-2 flex flex-col justify-center basis-1/2 gap-8'>
          <GetStartedForm sidebarLayout source={source} />
          </div>
        </div>
      </div>
  )
}

export function GetStartedTestimonial({testimonial=1, source='cta'}) {
  return (
    <div className='flex flex-col md:mx-6'>
      <div className='flex flex-col md:flex-row gap-4 md:gap-14 max-w-screen-xl mx-auto px-4 py-8'>
      <div className='flex flex-col justify-center gap-8 basis-1/2'>
      <GetStartedForm sidebarLayout source={source}  />
          </div>
      <div className="py-4 md:py-8 md:px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 basis-1/2">
            <div className="gap-8">
              { testimonial === 1 ? (
                <figure className="flex flex-col p-6 bg-white-2 rounded">
                    <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white text-pretty">Phone number really isn't needed!</h3>
                        <p className="my-4">"I loved that I didn't need to give out my phone number to get great advice. They craeted a telegram bot that connects the advisors to me so the advisors don't get my personal ID”</p>
                    </blockquote>
                    <figcaption className="flex items-center space-x-3 mt-auto">
                      <Image src='generic/Female 10.svg' className='w-16 h-16 rounded-full'/>
                        <div className="space-y-0.5 font-medium dark:text-white justity-start">
                            <div>Lynn</div>
                            <div>26</div>
                        </div>
                    </figcaption>    
                </figure>
              ) : testimonial === 2 ? (
                <figure className="flex flex-col p-6 bg-white-2 rounded">
                  <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Great experience</h3>
                    <p className="my-4">"I have some underlying conditions and I was amazed at how the advisors were able to give me very helpful suggestions."</p>
                  </blockquote>
                  <figcaption className="flex items-center space-x-3 mt-auto">
                    <Image src='generic/Male 12.svg' className='w-16 h-16 rounded-full'/>
                    <div className="space-y-0.5 font-medium dark:text-white">
                      <div>Joseph</div>
                      <div>35</div>
                    </div>
                  </figcaption>
                </figure>
              ) : testimonial === 3 ? (
                <figure className="flex flex-col p-6 bg-white-2 rounded">
                    <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Would recommend</h3>
                        <p className="my-4">"I appreciate this service because I can instantly see when an advisor tries to sell me things I don't need as their premiums are much higher than the rest."</p>
                    </blockquote>
                    <figcaption className="flex items-center space-x-3 mt-auto">
                    <Image src='generic/Female 4.svg' className='w-16 h-16 rounded-full'/>
                        <div className="space-y-0.5 font-medium dark:text-white">
                            <div>Amanda</div>
                            <div>25</div>
                        </div>
                    </figcaption>    
                </figure>
              ) : <figure className="flex flex-col p-6 bg-gray-50 rounded dark:bg-gray-800">
              <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Great experience</h3>
                <p className="my-4">"I have some underlying conditions and I was amazed at how the advisors were able to give me very helpful suggestions."</p>
              </blockquote>
              <figcaption className="flex items-center space-x-3 mt-auto">
                <Image src='generic/Male 12.svg' className='w-16 h-16 rounded-full'/>
                <div className="space-y-0.5 font-medium dark:text-white">
                  <div>Joseph</div>
                  <div>35</div>
                </div>
              </figcaption>
            </figure>
              }
            </div>   
      </div>
      
      </div>
    </div>
  )
}

export function GetStartedForm({ sidebarLayout = false, sample = false, source='cta' }) {
  const adVariation = useAdVariation();
  const { header, subhead, cta } = adVariations[adVariation || 'high-intent'];


  return (
    <div className={`${sidebarLayout ? 'py-4' : 'py-8 md:py-24'} text-center max-w-screen-2xl mx-auto`}>
      <h3 className="primary-h3 text-green-1 mb-4 text-balance capitalize text-start md:text-center" dangerouslySetInnerHTML={{ __html: header }}></h3>
      <p className="mb-8 max-w-screen-md mx-auto text-start md:text-center" dangerouslySetInnerHTML={{ __html: subhead }}></p>
      <GetStartedFormContent sidebarLayout={sidebarLayout} source={`${source}&variation=${adVariation}`} randomVariation={adVariation} cta={cta}/>
    </div>
  );
}



export function GetStarted({source='cta'}) {
  return <GetStartedForm source={source} />;
}

export function GetStartedSidebar({source='cta'}) {
  return <GetStartedForm sidebarLayout source={source} />;
}